import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import Link from './Link';

const styles = theme => ({
  button: theme.mixins.gutters({
    borderRadius: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      textDecoration: 'none',
    },
  }),
  navItem: {
    ...theme.typography.body2,
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  navLink: {
    fontWeight: theme.typography.fontWeightRegular,
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  navLinkButton: {
    color: theme.palette.text.secondary,
    textIndent: 24,
    fontSize: 13,
    paddingLeft: 10,
  },
  activeButton: {
    color: theme.palette.primary.main,
  },
});

const NavLink = ({ classes, href, title, icon, disabled }) => (
  <Tooltip title={title} placement="top-start">
    <ListItem
      button
      className={classNames(classes.navLink)}
      disableGutters
      disabled={disabled}>
      <Button
        component={Link}
        href={href}
        activeClassName={classes.activeButton}
        className={classNames(classes.button, classes.navLinkButton)}
        disableRipple>
        {icon}
        {title}
      </Button>
    </ListItem>
  </Tooltip>
);

NavLink.propTypes = {
  classes: PropTypes.shape({
    navLink: PropTypes.string,
    activeButton: PropTypes.string,
    button: PropTypes.string,
    navLinkButton: PropTypes.string,
  }).isRequired,
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

NavLink.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(NavLink);
