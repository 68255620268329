import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CheckIcon from '@material-ui/icons/Check';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as communitiesActions from '../../../thunks/Communities';
import {
  communitiesColumns,
  getValue,
} from '../../../utils/ListViewUtil';
import { withStyles, Button } from '@material-ui/core';
import CustomGrid from '../../../component/CustomGrid';
import Paper from '@material-ui/core/Paper';
import IconEdit from '@material-ui/icons/EditOutlined';
import { Button as SpinnerButton, Cell, CSVExporter } from '@tribiahq/interaxo-react-components';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { createStructuredSelector } from 'reselect';
import {
  selectCommunities,
  selectSearchTerm,
  selectIsLoadingCommunities,
  selectIsEnableLoading,
  selectIsDisableLoading
} from '../../../reducers/Communities';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import IconCreate from '@material-ui/icons/Add';

const styles = theme => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    height: '50%',
  },
  searchContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    display: 'flex',
    flexWrap: 'wrap',
    height: '20%',
  },
  searchField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '70%',
    height: '70px',
  },
  buttonContainer: {
    marginLeft: '5%',
    marginRight: theme.spacing(1),
    marginTop: 15,
    width: '10%',
    height: '100px',
  },
  noData: {
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    transform: 'none',
  },
  page: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  icon: {
    padding: 8,
    display: 'inline-block',
  },
});

const mapStateToProps = () =>
  createStructuredSelector({
    searchTerm: selectSearchTerm(),
    isLoading: selectIsLoadingCommunities(),
    communities: selectCommunities(),
    isEnableLoading: selectIsEnableLoading(),
    isDisableLoading: selectIsDisableLoading(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...communitiesActions }, dispatch),
});

class CommunitiesContainer extends React.Component {
  state = {
    term: '',
    clean: true,
  };

  componentDidMount() {
    const { searchTerm } = this.props;

    if (searchTerm || searchTerm === '') {
      this.setState({ term: searchTerm });
      this.handleSearchCommunities(searchTerm);
    }
  }

  handleSearchCommunities = term => {
    this.props.actions.findCommunities({ term });
  };

  handleEditClick = communityId =>
    this.props.history.push(`/communities/${communityId}`);

  handleCreateClick = () => this.props.history.push(`/communities/create`);

  handleExportClick = () => {
    new CSVExporter(this.props.communities, communitiesColumns).doExport();
  };

  handleChangeSearchTerm = event => this.setState({ term: event.target.value });

  getCommunityCellComponentRenderer = cell => {
    const value = getValue(cell.row, cell.column.name);
    if (value === true || value === false) {
      return (
        <div>
          {value && <CheckIcon />}
        </div>
      );
    }
  
    return cell.row[cell.column.name] || '';
  };

  renderEditCommunityButton = ({ row }) => {
    const { classes } = this.props;

    return (
      <Cell>
        <Tooltip title={'Edit community'}>
          <div>
            <IconButton
              className={classes.icon}
              onClick={() => this.handleEditClick(row.communityId)}>
              {<IconEdit />}
            </IconButton>
          </div>
        </Tooltip>
      </Cell>
    );
  };

  renderToolbarButtons = () => {
    return (
      <React.Fragment>
        <Tooltip title={'Create'}>
          <Fab size="small" color="primary" onClick={this.handleCreateClick}>
            <IconCreate />
          </Fab>
        </Tooltip>
        <Tooltip title={'Export'}>
          <Button
            style={{ marginLeft: 30 }}
            variant="contained"
            onClick={this.handleExportClick}>
            Export
          </Button>
        </Tooltip>
      </React.Fragment>
    );
  };

  render() {
    const { communities, classes, isLoading, isEnableLoading, isDisableLoading } = this.props;
    const { term } = this.state;

    return (
      <React.Fragment>
        <Paper className={classes.root}>
          {(isLoading || isEnableLoading || isDisableLoading) && <LinearProgress />}
          <div className={classes.searchContainer}>
            <TextField
              className={classes.searchField}
              label="Search term"
              placeholder="Search term"
              margin="normal"
              variant="outlined"
              value={term}
              onKeyPress={event => {
                // 13 is 'Enter' key code
                if (event.nativeEvent.keyCode === 13) {
                  this.handleSearchCommunities(this.state.term);
                }
              }}
              disabled={isLoading}
              helperText="*Leave blank if you want to see all communities"
              onChange={this.handleChangeSearchTerm}
            />
            <div className={classes.buttonContainer}>
              <SpinnerButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}
                spinner={isLoading}
                onClick={() => this.handleSearchCommunities(this.state.term)}>
                {'Search'}
              </SpinnerButton>
            </div>
          </div>
          <Divider />
          <CustomGrid
            columns={communitiesColumns}
            items={isLoading ? [] : communities}
            renderCellComponent={this.getCommunityCellComponentRenderer}
            renderToolbarButtons={this.renderToolbarButtons}
            editing={{
              renderEditButton: this.renderEditCommunityButton,
            }}
            height={window.innerHeight - 280}
            onItemCreateClick={() => this.handleCreateClick()}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

CommunitiesContainer.propTypes = {
  classes: PropTypes.shape({
    searchField: PropTypes.string,
    buttonContainer: PropTypes.string,
    searchContainer: PropTypes.string,
    noData: PropTypes.string,
    icon: PropTypes.string,
    page: PropTypes.string,
  }),
  response: PropTypes.shape({}),
  communities: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  searchTerm: PropTypes.string,
  actions: PropTypes.shape({
    findCommunities: PropTypes.func,
  }),
};

CommunitiesContainer.defaultProps = {
  classes: {},
  actions: {},
  communities: [],
  isLoading: false,
  response: {},
  searchTerm: '',
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(CommunitiesContainer));
