import axios from 'axios';
import { getEnvironmentVariables } from '../utils/Environment';

const checkStatus = status => status >= 200 && status < 300;

const client = axios.create({
  baseURL: getEnvironmentVariables().API_URL,
  headers: {
    Accept: 'application/json, application/xml, text/plain, text/html',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    'Accept-Language': localStorage.getItem('locale'),
  },
  withCredentials: true,
  validateStatus: checkStatus,
});

export { client };

export * from './Users';
export * from './Customers';
export * from './Communities';
export * from './TenantStores';
export * from './Rooms';
export * from './EnvironmentPreferences';
export * from './Members';
