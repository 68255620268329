import { createSelector } from 'reselect';
import Immutable from 'seamless-immutable';

export const types = {
  FIND_COMMUNITIES_REQUEST: 'FIND_COMMUNITIES_REQUEST',
  FIND_COMMUNITIES_SUCCESS: 'FIND_COMMUNITIES_SUCCESS',
  FIND_COMMUNITIES_ERROR: 'FIND_COMMUNITIES_ERROR',

  FETCH_COMMUNITY_REQUEST: 'FETCH_COMMUNITY_REQUEST',
  FETCH_COMMUNITY_SUCCESS: 'FETCH_COMMUNITY_SUCCESS',
  FETCH_COMMUNITY_ERROR: 'FETCH_COMMUNITY_ERROR',

  CREATE_COMMUNITY_REQUEST: 'CREATE_COMMUNITY_REQUEST',
  CREATE_COMMUNITY_SUCCESS: 'CREATE_COMMUNITY_SUCCESS',
  CREATE_COMMUNITY_ERROR: 'CREATE_COMMUNITY_ERROR',

  UPDATE_COMMUNITY_REQUEST: 'UPDATE_COMMUNITY_REQUEST',
  UPDATE_COMMUNITY_SUCCESS: 'UPDATE_COMMUNITY_SUCCESS',
  UPDATE_COMMUNITY_ERROR: 'UPDATE_COMMUNITY_ERROR',

  ENABLE_COMMUNITY_REQUEST: 'ENABLE_COMMUNITY_REQUEST',
  ENABLE_COMMUNITY_SUCCESS: 'ENABLE_COMMUNITY_SUCCESS',
  ENABLE_COMMUNITY_ERROR: 'ENABLE_COMMUNITY_ERROR',

  DISABLE_COMMUNITY_REQUEST: 'DISABLE_COMMUNITY_REQUEST',
  DISABLE_COMMUNITY_SUCCESS: 'DISABLE_COMMUNITY_SUCCESS',
  DISABLE_COMMUNITY_ERROR: 'DISABLE_COMMUNITY_ERROR',

  CLEAR_ACTIVE_COMMUNITY: 'CLEAR_ACTIVE_COMMUNITY',
};

export const storeIdentifier = 'communities';

const initialState = Immutable({
  searchTerm: null,
  communities: [],
  requested: false,
  isLoading: false,
  activeCommunity: undefined,
  isActiveCommunityRequested: null,
  isActiveCommunityLoading: false,
  isCreateCommunityRequested: false,
  isCreateCommunityLoading: false,
  isUpdateCommunityRequested: false,
  isUpdateCommunityLoading: false,
  isEnableCommunityLoading: false,
  isDisableCommunityLoading: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FIND_COMMUNITIES_REQUEST: {
      return state.merge({
        requested: true,
        isLoading: true,
      });
    }
    case types.FIND_COMMUNITIES_SUCCESS: {
      return state.merge({
        isLoading: false,
        communities: action.payload.communities,
        searchTerm: action.payload.searchTerm,
      });
    }
    case types.FIND_COMMUNITIES_ERROR: {
      return state.merge({
        isLoading: false,
      });
    }
    case types.FETCH_COMMUNITY_REQUEST: {
      return state.merge({
        isActiveCommunityRequested: {
          communityId: action.payload.communityId,
        },
        isActiveCommunityLoading: true,
      });
    }
    case types.FETCH_COMMUNITY_SUCCESS: {
      return state.merge({
        isActiveCommunityLoading: false,
        activeCommunity: action.payload.community,
      });
    }
    case types.FETCH_COMMUNITY_ERROR: {
      return state.merge({
        isActiveCommunityLoading: false,
      });
    }
    case types.CREATE_COMMUNITY_REQUEST: {
      return state.merge({
        isCreateCommunityRequested: true,
        isCreateCommunityLoading: true,
      });
    }
    case types.CREATE_COMMUNITY_SUCCESS: {
      return state.merge({
        isCreateCommunityLoading: false,
        activeCommunity: action.payload.community,
      });
    }
    case types.CREATE_COMMUNITY_ERROR: {
      return state.merge({
        isCreateCommunityLoading: false,
      });
    }
    case types.UPDATE_COMMUNITY_REQUEST: {
      return state.merge({
        isUpdateCommunityRequested: true,
        isUpdateCommunityLoading: true,
      });
    }
    case types.UPDATE_COMMUNITY_SUCCESS: {
      return state.merge({
        isUpdateCommunityLoading: false,
        activeCommunity: action.payload.community,
      });
    }
    case types.UPDATE_COMMUNITY_ERROR: {
      return state.merge({
        isUpdateCommunityLoading: false,
      });
    }
    case types.ENABLE_COMMUNITY_ERROR: {
      return state.merge({
        isEnableCommunityLoading: false,
      });
    }
    case types.ENABLE_COMMUNITY_SUCCESS: {
      return state.merge({
        isEnableCommunityLoading: false,
        activeCommunity: state.activeCommunity
          ? state.activeCommunity.merge({
              enabled:
                state.activeCommunity.communityId ===
                  action.payload.communityId || state.activeCommunity.enabled,
            })
          : state.activeCommunity,
        communities: state.communities.map(community => {
          if (community.communityId === action.payload.communityId) {
            return community.merge({ enabled: true });
          }

          return community;
        }),
      });
    }
    case types.ENABLE_COMMUNITY_REQUEST: {
      return state.merge({
        isEnableCommunityLoading: true,
      });
    }
    case types.DISABLE_COMMUNITY_ERROR: {
      return state.merge({
        isDisableCommunityLoading: false,
      });
    }
    case types.DISABLE_COMMUNITY_SUCCESS: {
      return state.merge({
        isDisableCommunityLoading: false,
        activeCommunity: state.activeCommunity
          ? state.activeCommunity.merge({
              enabled:
                state.activeCommunity.communityId === action.payload.communityId
                  ? false
                  : state.activeCommunity.enabled,
            })
          : state.activeCommunity,
        communities: state.communities.map(community => {
          if (community.communityId === action.payload.communityId) {
            return community.merge({ enabled: false });
          }

          return community;
        }),
      });
    }
    case types.DisABLE_COMMUNITY_REQUEST: {
      return state.merge({
        isDisableCommunityLoading: true,
      });
    }
    case types.CLEAR_ACTIVE_COMMUNITY: {
      return state.merge({
        activeCommunity: undefined,
        isActiveCommunityRequested: null,
      });
    }
    default:
      return state;
  }
}

// Actions
export const actions = {
  findCommunitiesRequest: () => ({
    type: types.FIND_COMMUNITIES_REQUEST,
  }),

  findCommunitiesSuccess: ({ communities, searchTerm }) => ({
    type: types.FIND_COMMUNITIES_SUCCESS,
    payload: { communities, searchTerm },
  }),

  findCommunitiesError: ({ error }) => ({
    type: types.FIND_COMMUNITIES_ERROR,
    payload: {
      error,
    },
  }),
  fetchCommunityRequest: ({ communityId }) => ({
    type: types.FETCH_COMMUNITY_REQUEST,
    payload: { communityId },
  }),

  fetchCommunitySuccess: ({ community }) => ({
    type: types.FETCH_COMMUNITY_SUCCESS,
    payload: { community },
  }),

  fetchCommunityError: ({ error }) => ({
    type: types.FETCH_COMMUNITY_ERROR,
    payload: {
      error,
    },
  }),
  createCommunityRequest: () => ({
    type: types.CREATE_COMMUNITY_REQUEST,
  }),

  createCommunitySuccess: ({ community }) => ({
    type: types.CREATE_COMMUNITY_SUCCESS,
    payload: { community },
  }),

  createCommunityError: ({ error }) => ({
    type: types.CREATE_COMMUNITY_ERROR,
    payload: {
      error,
    },
  }),
  updateCommunityRequest: () => ({
    type: types.UPDATE_COMMUNITY_REQUEST,
  }),

  updateCommunitySuccess: ({ community }) => ({
    type: types.UPDATE_COMMUNITY_SUCCESS,
    payload: { community },
  }),

  enableCommunityError: ({ error }) => ({
    type: types.ENABLE_COMMUNITY_ERROR,
    payload: {
      error,
    },
  }),

  enableCommunityRequest: ({ communityId }) => ({
    type: types.ENABLE_COMMUNITY_REQUEST,
    payload: { communityId },
  }),

  enableCommunitySuccess: ({ communityId }) => ({
    type: types.ENABLE_COMMUNITY_SUCCESS,
    payload: { communityId },
  }),

  disableCommunityError: ({ error }) => ({
    type: types.DISABLE_COMMUNITY_ERROR,
    payload: {
      error,
    },
  }),

  disableCommunityRequest: ({ communityId }) => ({
    type: types.DISABLE_COMMUNITY_REQUEST,
    payload: { communityId },
  }),

  disableCommunitySuccess: ({ communityId }) => ({
    type: types.DISABLE_COMMUNITY_SUCCESS,
    payload: { communityId },
  }),

  updateCommunityError: ({ error }) => ({
    type: types.UPDATE_COMMUNITY_ERROR,
    payload: {
      error,
    },
  }),
};

export const clearActiveCommunity = () => ({
  type: types.CLEAR_ACTIVE_COMMUNITY,
});

// Selectors
const communitiesSelector = () => state => state[storeIdentifier];

export const selectIsLoadingCommunities = () =>
  createSelector(
    communitiesSelector(),
    communitiesSelectorState => communitiesSelectorState.isLoading,
  );

export const selectIsRequestedCommunities = () =>
  createSelector(
    communitiesSelector(),
    communitiesSelectorState => communitiesSelectorState.requested,
  );

export const selectIsLoadingCreateCommunity = () =>
  createSelector(
    communitiesSelector(),
    communitiesSelectorState =>
      communitiesSelectorState.isCreateCommunityLoading,
  );

export const selectIsRequestedCreateCommunity = () =>
  createSelector(
    communitiesSelector(),
    communitiesSelectorState =>
      communitiesSelectorState.isCreateCommunityRequested,
  );

export const selectIsLoadingUpdateCommunity = () =>
  createSelector(
    communitiesSelector(),
    communitiesSelectorState =>
      communitiesSelectorState.isUpdateCommunityLoading,
  );

export const selectIsRequestedUpdateCommunity = () =>
  createSelector(
    communitiesSelector(),
    communitiesSelectorState =>
      communitiesSelectorState.isUpdateCommunityRequested,
  );

export const selectCommunities = () =>
  createSelector(
    communitiesSelector(),
    communitiesSelectorState => communitiesSelectorState.communities,
  );

export const selectSearchTerm = () =>
  createSelector(
    communitiesSelector(),
    communitiesSelectorState => communitiesSelectorState.searchTerm,
  );

export const selectActiveCommunity = id =>
  createSelector(communitiesSelector(), communitiesSelectorState =>
    communitiesSelectorState.activeCommunity &&
    communitiesSelectorState.activeCommunity.communityId === id
      ? communitiesSelectorState.activeCommunity
      : undefined,
  );

export const selectIsActiveCommunityLoading = () =>
  createSelector(
    communitiesSelector(),
    communitiesSelectorState =>
      communitiesSelectorState.isActiveCommunityLoading,
  );

export const selectIsActiveCommunityRequested = () =>
  createSelector(
    communitiesSelector(),
    communitiesSelectorState =>
      communitiesSelectorState.isActiveCommunityRequested,
  );

export const selectIsEnableLoading = () =>
  createSelector(
    communitiesSelector(),
    communitiesSelectorState =>
      communitiesSelectorState.isEnableCommunityLoading,
  );

export const selectIsDisableLoading = () =>
  createSelector(
    communitiesSelector(),
    communitiesSelectorState =>
      communitiesSelectorState.isDisableCommunityLoading,
  );
