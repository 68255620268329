import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  selectCustomers,
  selectIsLoadingCustomers,
  selectIsRequestedCustomers,
} from '../../../reducers/Customers';
import * as customersActions from '../../../thunks/Customers';
import { createStructuredSelector } from 'reselect';
import LinearProgress from '@material-ui/core/LinearProgress';

const mapStateToProps = () =>
  createStructuredSelector({
    customers: selectCustomers(),
    isLoading: selectIsLoadingCustomers(),
    isRequested: selectIsRequestedCustomers(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...customersActions }, dispatch),
});

class CustomersContainer extends React.Component {
  componentDidMount() {
    const { isLoading, isRequested } = this.props;

    if (!isLoading && !isRequested) {
      this.props.actions.fetchCustomers();
    }
  }

  render() {
    const { children, isLoading } = this.props;

    return isLoading ? <LinearProgress /> : <div>{children}</div>;
  }
}

CustomersContainer.propTypes = {
  isLoading: PropTypes.bool,
  isRequested: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  actions: PropTypes.shape({
    fetchCustomers: PropTypes.func,
  }),
};

CustomersContainer.defaultProps = {
  isLoading: false,
  isRequested: false,
  actions: {},
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomersContainer),
);
