import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { fetchRooms, createTemplateRooms } from '../../../thunks/Rooms';
import { actions } from '../../../reducers/Rooms';
import {
  templateRoomsColumns,
  editableTemplateRoomsColumns,
  NEW_TITLE,
} from '../../../utils/ListViewUtil';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Table, Cell } from '@tribiahq/interaxo-react-components';
import useEnvironmentPreferences from '../../../effects/useEnvironmentPreferences';
import useDebounced from '../../../effects/useDebounced';

import { CellEditor } from '../../../component/CellEditor';
import useCreateTemplateRooms from '../../../effects/useCreateTemplateRooms';
import useRooms from '../../../effects/useRooms';
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';

const styles = theme => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40%',
  },
  buttonContainer: {
    marginLeft: '5%',
    marginRight: theme.spacing(1),
    marginTop: 26,
    width: '10%',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
});

function RoomsContainer({ classes }) {
  const { handleSubmit, setValue, errors, control } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      'from-value': '',
      'to-value': '',
    },
  });
  const [communityId, setCommunityId] = useState('');
  const [selected, setSelected] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const dispatch = useDispatch();
  const rooms = useRooms();
  const templateRooms = rooms.rooms.filter(room => room.template);
  const createTemplateRoomsStatus = useCreateTemplateRooms();
  const environmentPreferences = useEnvironmentPreferences();

  useEffect(() => {
    if (
      environmentPreferences.isRequested &&
      !environmentPreferences.isLoading &&
      environmentPreferences.preferences
    ) {
      const favoriteTemplateCommunity =
        environmentPreferences.preferences.favoriteTemplateCommunityId;
      setValue('from-value', favoriteTemplateCommunity);
      setCommunityId(favoriteTemplateCommunity);
    }
  }, [
    setValue,
    setCommunityId,
    environmentPreferences.isRequested,
    environmentPreferences.isLoading,
    environmentPreferences.preferences,
  ]);

  useDebounced({
    delayedFunction: async () => {
      const needToReFetchRooms =
        (!rooms.isRequested || rooms.isRequested.communityId !== communityId) &&
        !rooms.isLoading;
      const invalid = !!errors['from-value'];
      const allowedToFetch = !!(communityId && communityId.length >= 5);

      if (invalid || !allowedToFetch) {
        return;
      }
      if (needToReFetchRooms) {
        setSelected([]);
        setSelectedRooms([]);
        dispatch(actions.clearRooms());
        dispatch(fetchRooms({ communityId }));
      }
    },
    delay: 500,
    dependencies: [
      rooms.isLoading,
      rooms.isRequested,
      communityId,
      errors,
      dispatch,
      setSelected,
      setSelectedRooms,
    ],
  });

  const convertRoomIntoModel = room => ({
    templateTenant: communityId,
    templateNodeId: room.nodeId,
    shortName: room.shortName,
    title: room.title,
    newTitle: room.title,
    template: true,
    withContent: false,
    description: room.description,
    workspace: room.workspace,
  });

  const convertModelIntoDTO = model => ({
    templateTenant: model.templateTenant,
    templateNodeId: model.templateNodeId,
    shortName: model.shortName,
    title: model.newTitle,
    description: model.description,
    visibility: 'PUBLIC',
    sitePreset: model.template ? 'site-templates' : 'site-dashboard',
    workspaceId: model.workspace,
    withContent: model.withContent,
  });

  const onSubmit = params => {
    const targetCommunityId = params['to-value'];
    const fromCommunityId = params['from-value'];
    const templateRoomsDTOs = selectedRooms.map(selectedRoom =>
      convertModelIntoDTO(selectedRoom, fromCommunityId),
    );
    dispatch(
      createTemplateRooms({
        communityId: targetCommunityId,
        templateRooms: templateRoomsDTOs,
      }),
    );
  };

  const handleFromChange = communityId => {
    if (!errors['from-value']) {
      setCommunityId(communityId);
    }
  };

  if (!environmentPreferences.isRequested || environmentPreferences.isLoading) {
    return <LinearProgress />;
  }

  const generateShortName = title =>
    title
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/(?!-)[\W_]/g, '');

  const validationRules = {
    newTitle: [
      {
        isValid: value => value?.trim().length > 0,
        errorText: 'The field is required',
      },
      {
        isValid: value => !/[/?*:"><|\\]/.test(value),
        errorText:
          'The field cannot contain the following characters: / \\\\ ? * : " > < |',
      },
      {
        isValid: value => !/(^[\s*])/i.test(value),
        errorText: 'The field cannot start with a space',
      },
    ],
  };

  const handleSelectionChange = selection => {
    setSelected(selection);

    if (selection.length === 0) {
      setSelectedRooms([]);
      return;
    }

    let savedSelected = selectedRooms.filter(room =>
      selection.includes(room.templateNodeId),
    );
    let newlySelected = selection
      .filter(
        nodeId => !savedSelected.some(room => room.templateNodeId === nodeId),
      )
      .map(nodeId => templateRooms.find(room => room.nodeId === nodeId))
      .map(room => convertRoomIntoModel(room));

    setSelectedRooms(savedSelected.concat(newlySelected));
  };

  const handleCellChange = (row, columnName, newValue) => {
    const newModel = row;
    newModel[columnName] = newValue;

    if (columnName === NEW_TITLE) {
      newModel.shortName = generateShortName(newValue);
    }

    const newSelectedRooms = selectedRooms.map(room =>
      room.templateNodeId === newModel.templateNodeId ? newModel : room,
    );

    setSelectedRooms(newSelectedRooms);
  };

  const getCellComponentRenderer = props => (
    <Cell className={classes.cell} {...props}>
      <CellEditor
        record={props.row}
        fieldName={props.column.name}
        onValueChange={newValue =>
          handleCellChange(props.row, props.column.name, newValue)
        }
        disabled={props.column.readOnly}
        type={props.column.type}
        validators={validationRules[props.column.name]}
      />
    </Cell>
  );

  return (
    <Paper className={classes.root}>
      {createTemplateRoomsStatus.isLoading && <LinearProgress />}
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Controller
          control={control}
          name="from-value"
          rules={{
            required: 'Required',
            pattern: {
              value: /^[A-Za-z0-9._-]+$/i,
              message: 'Invalid communityId',
            },
          }}
          render={({ onChange, onBlur, value, name, ref }) => (
            <TextField
              id="from-value"
              className={classes.textField}
              label="From"
              placeholder="Community ID"
              margin="normal"
              variant="outlined"
              onChange={e => {
                handleFromChange(e.target.value);
                onChange(e.target.value);
              }}
              error={!!errors['from-value']}
              helperText={errors['from-value'] && errors['from-value'].message}
              inputRef={ref}
              value={value}
            />
          )}
        />
        <Controller
          control={control}
          name="to-value"
          onChange={event => handleFromChange(event.target.value)}
          rules={{
            required: 'Required',
            pattern: {
              value: /^[A-Za-z0-9._-]+$/i,
              message: 'Invalid communityId',
            },
          }}
          render={({ onChange, onBlur, value, name, ref }) => (
            <TextField
              id="to-value"
              className={classes.textField}
              label="To"
              placeholder="Community ID"
              margin="normal"
              variant="outlined"
              onChange={onChange}
              error={!!errors['to-value']}
              helperText={errors['to-value'] && errors['to-value'].message}
              inputRef={ref}
              value={value}
            />
          )}
        />
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={
              !!(errors['from-value'] || errors['to-value']) ||
              createTemplateRoomsStatus.isLoading
            }>
            Go
          </Button>
        </div>
      </form>
      <Table
        columns={templateRoomsColumns}
        rows={templateRooms}
        virtual
        height={300}
        gridConfig={{
          getRowId: row => row.nodeId,
        }}
        columnResizing={{
          defaultColumnWidths: templateRoomsColumns,
        }}
        search={{
          panelConfig: {
            messages: {
              searchPlaceholder: 'Search...',
            },
          },
        }}
        sorting={{
          stateConfig: {
            defaultSorting: [
              { columnName: 'template', direction: 'desc' },
              { columnName: 'title', direction: 'asc' },
            ],
          },
        }}
        selection={{
          stateConfig: {
            selection: selected,
            onSelectionChange: handleSelectionChange,
          },
          config: {
            showSelectAll: true,
            headerCellComponent: TableSelection.HeaderCell,
            cellComponent: TableSelection.Cell,
            selectByRowClick: true,
            highlightRow: true,
            rowAlternationEnabled: true,
          },
        }}
        tableConfig={{
          cellComponent: props => {
            if (props.column.customComponent) {
              return (
                <Cell {...props}>
                  {props.column.customComponent(props.row)}
                </Cell>
              );
            }
            return <Cell {...props} />;
          },
        }}
      />

      <Table
        columns={editableTemplateRoomsColumns}
        rows={selectedRooms.map(item => ({ ...item }))}
        virtual
        height={300}
        gridConfig={{
          getRowId: row => row.templateNodeId,
        }}
        columnResizing={{
          defaultColumnWidths: editableTemplateRoomsColumns,
        }}
        search={{
          panelConfig: {
            messages: {
              searchPlaceholder: 'Search...',
            },
          },
        }}
        sorting={{
          stateConfig: {
            defaultSorting: [{ columnName: 'title', direction: 'asc' }],
          },
        }}
        tableConfig={{
          cellComponent: getCellComponentRenderer,
          estimatedRowHeight: 49,
        }}
      />
    </Paper>
  );
}

RoomsContainer.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    textField: PropTypes.string,
    buttonContainer: PropTypes.string,
    cell: PropTypes.string,
    form: PropTypes.string,
  }),
};

RoomsContainer.defaultProps = {
  classes: {},
};

export default withStyles(styles)(RoomsContainer);
