import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  button: theme.mixins.gutters({
    borderRadius: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      textDecoration: 'none',
    },
  }),
  navItem: {
    ...theme.typography.body2,
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  navLink: {
    fontWeight: theme.typography.fontWeightRegular,
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  navLinkButton: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    paddingLeft: 10,
    paddingTop: 8,
    paddingBottom: 8,
    textDecoration: 'none',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 13,
    lineHeight: '1.4em',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
  },
});

const NavExternalLink = ({ classes, href, title, icon, disabled }) => (
  <Tooltip title={title} placement="top-start">
    <ListItem
      button
      className={classNames(classes.navLink)}
      disableGutters
      disabled={disabled}>
      <a
        className={classNames(classes.button, classes.navLinkButton)}
        href={href}
        target="_blank"
        rel="noopener noreferrer">
        <span className={classNames(classes.label)}>
          {icon}
          {title}
        </span>
      </a>
    </ListItem>
  </Tooltip>
);

NavExternalLink.propTypes = {
  classes: PropTypes.shape({
    navLink: PropTypes.string,
    button: PropTypes.string,
    navLinkButton: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

NavExternalLink.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(NavExternalLink);
