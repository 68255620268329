import { createSelector } from 'reselect';
import Immutable from 'seamless-immutable';

export const types = {
  FETCH_ENVIRONMENT_PREFERENCES_REQUEST:
    'FETCH_ENVIRONMENT_PREFERENCES_REQUEST',
  FETCH_ENVIRONMENT_PREFERENCES_SUCCESS:
    'FETCH_ENVIRONMENT_PREFERENCES_SUCCESS',
  FETCH_ENVIRONMENT_PREFERENCES_ERROR: 'FETCH_ENVIRONMENT_PREFERENCES_ERROR',
};

export const storeIdentifier = 'environmentPreferences';

const initialState = Immutable({
  environmentPreferences: null,
  requested: false,
  isLoading: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ENVIRONMENT_PREFERENCES_REQUEST: {
      return state.merge({
        requested: true,
        isLoading: true,
      });
    }
    case types.FETCH_ENVIRONMENT_PREFERENCES_SUCCESS: {
      return state.merge({
        isLoading: false,
        environmentPreferences: action.payload.environmentPreferences,
      });
    }
    case types.FETCH_ENVIRONMENT_PREFERENCES_ERROR: {
      return state.merge({
        isLoading: false,
      });
    }
    default:
      return state;
  }
}

// Actions
export const actions = {
  fetchEnvironmentPreferencesRequest: () => ({
    type: types.FETCH_ENVIRONMENT_PREFERENCES_REQUEST,
  }),

  fetchEnvironmentPreferencesSuccess: ({ environmentPreferences }) => ({
    type: types.FETCH_ENVIRONMENT_PREFERENCES_SUCCESS,
    payload: { environmentPreferences },
  }),

  fetchEnvironmentPreferencesError: ({ error }) => ({
    type: types.FETCH_ENVIRONMENT_PREFERENCES_ERROR,
    payload: {
      error,
    },
  }),
};

// Selectors
const environmentPreferencesSelector = () => state => state[storeIdentifier];

export const selectIsLoadingEnvironmentPreferences = () =>
  createSelector(
    environmentPreferencesSelector(),
    environmentPreferencesSelectorState =>
      environmentPreferencesSelectorState.isLoading,
  );

export const selectIsRequestedEnvironmentPreferences = () =>
  createSelector(
    environmentPreferencesSelector(),
    environmentPreferencesSelectorState =>
      environmentPreferencesSelectorState.requested,
  );

export const selectEnvironmentPreferences = () =>
  createSelector(
    environmentPreferencesSelector(),
    environmentPreferencesSelectorState =>
      environmentPreferencesSelectorState.environmentPreferences,
  );
