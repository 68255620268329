import { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';

const useDebounced = ({ delayedFunction, delay, dependencies }) => {
  const delayed = useCallback(debounce(delayedFunction, delay), dependencies);

  useEffect(() => {
    delayed();

    return delayed.cancel;
  }, [delayed]);
};

export default useDebounced;
