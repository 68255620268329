const RELEASE_NAME = 'sysadmin';
const debug = false; // if ReactGA debug statements should be put into console

export const newrelic = window.newrelic;

// Trackable redux actions (splitted based on event categories)
const events = {};

export const initialize = () => {
  //New Relic enabled only for Stage and Prod
  if (newrelic) {
    newrelic.addRelease(
      RELEASE_NAME,
      `${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_GIT_SHA}`,
    );
  }
};

export const initializeSession = ({ userName, groups, currentCommunity }) => {
  //New Relic enabled only for Stage and Prod
  if (newrelic) {
    // New Relic custom session attributes
    newrelic.setCustomAttribute('communityId', currentCommunity.communityId);
    newrelic.setCustomAttribute('userName', userName);
  }
};

export const logPageView = () => {
  const hash = window.location.hash;
  const queryParamIndex = hash.lastIndexOf('?');
  const page =
    queryParamIndex > 0
      ? hash.substring(2, queryParamIndex)
      : hash.substring(2);

  if (newrelic) {
    newrelic.setCurrentRouteName(page);
  }
};

export const logEvent = ({ category, action, label = '', value = 0 }) => {
  if (category && action) {
    //New Relic enabled only for Stage and Prod
    if (newrelic) {
      newrelic
        .interaction()
        .setAttribute('category', category)
        .setAttribute('action', action)
        .setAttribute('label', label)
        .setAttribute('value', value)
        .save();
    }
  }
};

export const logException = ({
  type,
  action,
  code,
  message,
  fatal = false,
  error,
}) => {
  if (newrelic) {
    newrelic.noticeError(error, {
      type,
      code,
      action,
      fatal,
    });
  }
};

/* eslint-disable no-console */
export const logAction = action => {
  const actionType = action && action.type ? action.type : '';
  const event = events[actionType];
  // Ignore non-trackable redux actions
  if (event) {
    logEvent(typeof event === 'function' ? event.call(null, action) : event);
  } else if (debug) {
    console.log(`Action '${actionType}' is not tracked in google analytics`);
  }
};
/* eslint-enable no-console */

/* eslint-disable no-unused-vars */
export const actionLogger = store => next => action => {
  logAction(action);
  return next(action);
};
/* eslint-enable no-unused-vars */
