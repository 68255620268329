import React, { useEffect, useState } from 'react';
import red from '@material-ui/core/colors/red';
import PropTypes from 'prop-types';

import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { fetchEnvironmentPreferences } from './thunks/EnvironmentPreferences';

import Sidebar from './component/Sidebar';
import Header from './component/Header';

import createRoutes from './routes';
import Notifications from './component/Notifications';
import useEnvironmentPreferences from './effects/useEnvironmentPreferences';

const muiTheme = createMuiTheme({
  overrides: {
    MuiTableRow: {
      root: {
        height: 30,
      },
      head: {
        height: 30,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#e6f5fb',
      },
    },
    MuiPickersDay: {
      '&$selected': {
        backgroundColor: '#e6f5fb',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '0.875rem',
        fontWeight: 500,
      },
    },
  },
  palette: {
    primary: {
      light: '#42719a',
      main: '#13627c',
      dark: '#063544',
      contrastText: '#fff',
    },
    secondary: {
      light: '#42719a',
      main: '#13627c',
      dark: '#063544',
      contrastText: '#fff',
    },
    error: red,
    highlight: '#FFF3E0',
    type: 'light',
  },
});

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    paddingTop: 24,
    height: 'calc(100% - 56px)',
    marginTop: 40,
  },
});

function App({ classes, }) {
  const [showSidebar, setShowSidebar] = useState(false);
  const dispatch = useDispatch();
  const preferences = useEnvironmentPreferences();

  useEffect(() => {
    if (preferences.isRequested || preferences.isLoading) {
      return;
    }

    (async () => {
      await dispatch(fetchEnvironmentPreferences());
    })();

  }, [preferences.isRequested, preferences.isLoading, dispatch]);

  const calculateMarginLeft = () => {
    if (window.matchMedia('(max-width: 960px)').matches) {
      return 0;
    }
    if (showSidebar) {
      return 250;
    }
    return 45;
  };

  const marginLeft = calculateMarginLeft();
  const width = `calc(100% - ${marginLeft}px)`;

  return (
    <MuiThemeProvider theme={muiTheme}>
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <Sidebar
            show={showSidebar}
            onMenuClick={() => setShowSidebar(!showSidebar)}
          />
          <Header
            sidebarOpen={showSidebar}
            onSidebarClick={() => setShowSidebar(!showSidebar)}
          />
          <Notifications />
          <div className={classes.content} style={{ width, marginLeft }}>
            {createRoutes()}
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  );
}

App.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    appFrame: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(App);
