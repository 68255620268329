import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/icons/Menu';
import Help from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import { useAuth0 } from '@tribiahq/interaxo-react-components';
import Button from '@material-ui/core/Button';

const style = theme => ({
  flexGrow: {
    flex: '1 1 auto',
  },
  appBar: {
    backgroundColor: 'white',
    position: 'fixed',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 45,
      width: 'calc(100% - 45px)',
    },
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  iconElementLeft: {
    display: 'flex',
    color: 'black',
    padding: 10,
  },
  sidebarButton: {
    padding: 0,
  },
  helpButton: {
    height: 42,
    width: 42,
    color: 'black',
  },
  menuIcon: {
    color: 'black',
  },
  avatar: {
    flex: 1,
    paddingRight: 10,
  },
  userInitials: {
    flex: 4,
    '& h3': {
      margin: 0,
    },
    '& p': {
      fontStyle: 'italic',
      margin: 0,
    },
    '& span': {
      display: 'inline-block',
      paddingTop: '7px',
    },
    '& a': {
      cursor: 'pointer',
    },
  },
  userMenu: {
    height: 170,
    width: 300,
    display: 'flex',
    flexDirection: 'column',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    flex: 3,
  },
  bottomMenu: {
    borderTop: '1px solid #e6f5fb',
    padding: 10,
    flex: 1,
  },
  appBarShift: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '100%',
      zIndex: 1199,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 250,
      width: 'calc(100% - 250px)',
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  titleShift: {
    marginLeft: 16,
  },
});

const Header = ({ onSidebarClick, classes, sidebarOpen }) => {
  const { logout } = useAuth0();

  const handleLogout = () => logout();

  return (
    <AppBar
      className={classNames(
        classes.appBar,
        sidebarOpen && classes.appBarShift,
      )}>
      <Toolbar disableGutters>
        {!sidebarOpen && (
          <div className={classes.iconElementLeft}>
            <Tooltip title="Toggle">
              <IconButton
                className={classes.sidebarButton}
                onClick={onSidebarClick}>
                <Menu className={classes.menuIcon} />
              </IconButton>
            </Tooltip>
          </div>
        )}
        <div className={sidebarOpen ? classes.titleShift : null}>
          <Typography variant="h6">Title</Typography>
          <Typography variant="caption">Caption</Typography>
        </div>
        <div className={classes.flexGrow} />
        <Tooltip title="Logout">
          <Button variant="contained" color="primary" onClick={handleLogout}>
            Logout
          </Button>
        </Tooltip>
        <Tooltip title="Help">
          <IconButton onClick={() => {}}>
            <Help className={classes.helpButton} />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

/**
 *
 * @type {Object}
 */

Header.propTypes = {
  classes: PropTypes.shape({
    appBar: PropTypes.string,
    appBarShift: PropTypes.string,
    iconElementLeft: PropTypes.string,
    menuIcon: PropTypes.string,
    sidebarButton: PropTypes.string,
    flexGrow: PropTypes.string,
    helpButton: PropTypes.string,
    titleShift: PropTypes.string,
  }).isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  onSidebarClick: PropTypes.func.isRequired,
};

export default withStyles(style)(Header);
