import PropTypes from 'prop-types';
import Immutable from 'seamless-immutable';

export const Customer = {
  shape: PropTypes.shape({
    customerName: PropTypes.string,
    customerNumber: PropTypes.string,
    supplierId: PropTypes.string,
    nodeId: PropTypes.string,
    splitCostAcrossCommunities: PropTypes.bool,
    contractId: PropTypes.string,
  }),
  defaultValue: Immutable({
    customerName: '',
    customerNumber: '',
    supplierId: '',
    nodeId: '',
    splitCostAcrossCommunities: false,
    contractId: '',
  }),
};
