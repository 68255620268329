import { useSelector } from 'react-redux';
import {
  selectEnvironmentPreferences,
  selectIsLoadingEnvironmentPreferences,
  selectIsRequestedEnvironmentPreferences,
} from '../reducers/EnvironmentPreferences';

const useEnvironmentPreferences = () => {
  const isRequested = useSelector(selectIsRequestedEnvironmentPreferences());
  const isLoading = useSelector(selectIsLoadingEnvironmentPreferences());
  const preferences = useSelector(selectEnvironmentPreferences());

  return {
    isRequested,
    isLoading,
    preferences,
  };
};

export default useEnvironmentPreferences;
