import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { selectIsLoading } from '../../reducers/Members';
import { changeNativeCommunity } from '../../thunks/Members';


const styles = theme => ({
    root: {
      margin: theme.spacing(3),
      padding: theme.spacing(3),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '60%',
    },
    buttonContainer: {
      marginLeft: '8px',
      marginRight: theme.spacing(1),
      marginTop: 26,
      width: '10%',
    },
    form: {
      width: '100%',
    }
  });

const defaultUsername = '';
const defaultUsers = ``;
function ChangeCommunityContainer({ classes }) {
    const { handleSubmit, reset, errors, control } = useForm({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        'username': defaultUsername,
        'users': defaultUsers,
      }
    });
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoading());
    useEffect(() => {
        if (!isLoading) {
            reset();
        }
    }, [reset, isLoading]);

    const onSubmit = (params) => {
      const username = params['username'];
      const users = params['users'];
      dispatch(changeNativeCommunity({ username, users }));
    }

    return (
        <Paper className={classes.root}>
          {isLoading && <LinearProgress />}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.form}
          >
            <Controller
              control={control}
              name="username"
              rules={{
                required: "Required",
                pattern: {
                  value: /^[A-Za-z0-9-.~A-Za-z0-9._-]+$/i,
                  message: "Invalid username"
                }
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <TextField
                  id="username"
                  className={classes.textField}
                  label="Username"
                  placeholder={'user~example.com'}
                  margin="normal"
                  variant="outlined"
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  error={!!errors['username']}
                  helperText={errors['username'] && errors['username'].message}
                  inputRef={ref}
                  value={value}
                />
              )}
            />
            <Controller
              control={control}
              name="users"
              onChange={(event) => {}}
              rules={{
                required: "Required",
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <TextField
                  id="users"
                  className={classes.textField}
                  label="Users"
                  placeholder={`[
    "user~example.com@native-community",
    "user~example.com@another-communinty"
]`}
                  margin="normal"
                  variant="outlined"
                  onChange={onChange}
                  error={!!errors['users']}
                  helperText={errors['users'] && errors['users'].message}
                  inputRef={ref}
                  value={value}
                  multiline
                  rows={6}
                />
              )}
            />
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
              >
                Change
              </Button>
            </div>
          </form>
        </Paper>
      );
}

export default withStyles(styles)(ChangeCommunityContainer);