import { createSelector } from 'reselect';
import Immutable from 'seamless-immutable';

export const types = {
  FETCH_TENANT_STORES_REQUEST: 'FETCH_TENANT_STORES_REQUEST',
  FETCH_TENANT_STORES_SUCCESS: 'FETCH_TENANT_STORES_SUCCESS',
  FETCH_TENANT_STORES_ERROR: 'FETCH_TENANT_STORES_ERROR',
};

export const storeIdentifier = 'tenantStores';

const initialState = Immutable({
  tenantStores: [],
  requested: false,
  isLoading: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_TENANT_STORES_REQUEST: {
      return state.merge({
        requested: true,
        isLoading: true,
      });
    }
    case types.FETCH_TENANT_STORES_SUCCESS: {
      return state.merge({
        isLoading: false,
        tenantStores: action.payload.tenantStores,
      });
    }
    case types.FETCH_TENANT_STORES_ERROR: {
      return state.merge({
        isLoading: false,
      });
    }
    default:
      return state;
  }
}

// Actions
export const actions = {
  fetchTenantStoresRequest: () => ({
    type: types.FETCH_TENANT_STORES_REQUEST,
  }),

  fetchTenantStoresSuccess: ({ tenantStores }) => ({
    type: types.FETCH_TENANT_STORES_SUCCESS,
    payload: { tenantStores },
  }),

  fetchTenantStoresError: ({ error }) => ({
    type: types.FETCH_TENANT_STORES_ERROR,
    payload: {
      error,
    },
  }),
};

// Selectors
const storageSelector = () => state => state[storeIdentifier];

export const selectIsLoadingTenantStores = () =>
  createSelector(
    storageSelector(),
    storageSelectorState => storageSelectorState.isLoading,
  );

export const selectIsRequestedTenantStores = () =>
  createSelector(
    storageSelector(),
    storageSelectorState => storageSelectorState.requested,
  );

export const selectTenantStores = () =>
  createSelector(
    storageSelector(),
    storageSelectorState => storageSelectorState.tenantStores,
  );
