import Check from '@material-ui/icons/Check';
import React from 'react';
import { getBoolDisplayValue } from '@tribiahq/interaxo-react-components';

//common
export const CUSTOMER_NUMBER = 'customerNumber';
export const CUSTOMER_NAME = 'customerName';
export const SUPPLIER_ID = 'supplierId';

//customers
export const SPLIT_COST = 'splitCostAcrossCommunities';
export const NODE_ID = 'nodeId';
export const CONTRACT_ID = 'contractId';

//communities
export const COMMUNITY_ID = 'communityId';
export const COMMUNITY_NAME = 'communityName';
export const INVOICE_LEVEL = 'invoiceLevel';
export const AGREEMENT_ID = 'agreementId';
export const PROJECT = 'project';
export const STATUS = 'enabled';
export const SIZE = 'lastRecordedSize';
export const SALES_CONTACT_NAME = 'salesContactName';
export const SALES_CONTACT_EMAIL = 'salesContactEmail';
export const CUSTOMER_CONTACT_NAME = 'customerContactName';
export const CUSTOMER_CONTACT_EMAIL = 'customerContactEmail';
//features
export const ON_SITE = 'onSiteEnabled';
export const GENERATOR = 'generatorEnabled';
export const PRINT_ORDER = 'printOrderEnabled';
export const DRAWING_MANAGEMENT = 'drawingManagementEnabled';
export const WORKSPACES = 'workspacesEnabled';
export const SHARED_USERS = 'sharedUsersEnabled';
export const VISUAL_BOARDS = 'visualBoardsEnabled';
export const PUBLIC_LINKS = 'publicLinksEnabled';
export const DISTRIBUTION_GROUPS = 'communityGroupEnabled';
export const MFA = 'mfaRequired';
export const INTERNAL_COMMENTS = 'internalCommentsEnabled';
export const EXTERNAL_PUBLISH = 'externalPublishEnabled';
export const CREATE_ROOM = 'createRoomEnabled';
export const ORDER_ROOM = 'orderRoomEnabled';
export const PROJECT_CONTEXT = 'projectContextEnabled';

//template room
export const DESCRIPTION = 'description';
export const TITLE = 'title';
export const TEMPLATE = 'template';
export const AS_TEMPLATE = 'template';
export const SHORT_NAME = 'shortName';
export const NEW_TITLE = 'newTitle';
export const WITH_CONTENT = 'withContent';

export const customersColumns = [
  {
    name: CUSTOMER_NUMBER,
    title: 'Customer Number',
  },
  {
    name: CUSTOMER_NAME,
    title: 'Customer Name',
  },
  {
    name: SUPPLIER_ID,
    title: 'Supplier ID',
  },
  {
    name: SPLIT_COST,
    title: 'Split cost',
    getCellValue: item => getBoolDisplayValue(item[SPLIT_COST]),
  },
  {
    name: NODE_ID,
    title: 'Node ID',
  },
  {
    name: CONTRACT_ID,
    title: 'Contract ID',
  },
];

export const communitiesColumns = [
  {
    name: COMMUNITY_ID,
    title: 'Community ID',
    width: 150,
  },
  {
    name: COMMUNITY_NAME,
    title: 'Community Name',
  },
  {
    name: CUSTOMER_NUMBER,
    title: 'Customer Number',
    width: 100,
  },
  {
    name: SUPPLIER_ID,
    title: 'Supplier ID',
    width: 100,
  },
  {
    name: INVOICE_LEVEL,
    title: 'Invoice on',
    width: 100,
  },
  {
    name: AGREEMENT_ID,
    title: 'Agreement ID',
    width: 100,
  },
  {
    name: CUSTOMER_NAME,
    title: 'Customer Name',
  },
  {
    name: PROJECT,
    title: 'Project',
    width: 100,
  },
  {
    name: STATUS,
    title: 'Status [enabled / disabled]',
  },
  {
    name: SIZE,
    title: 'Size',
    width: 100,
  },
  {
    name: SALES_CONTACT_NAME,
    title: 'Sales Contact Name',
    width: 200,
  },
  {
    name: SALES_CONTACT_EMAIL,
    title: 'Sales Contact Email',
    width: 200,
  },
  {
    name: CUSTOMER_CONTACT_NAME,
    title: 'Customer Contact Name',
    width: 200,
  },
  {
    name: CUSTOMER_CONTACT_EMAIL,
    title: 'Customer Contact Email',
    width: 200,
  },
  {
    name: `features.${ON_SITE}`,
    title: 'Interaxo onSite mobile',
    getCellValue: item => getBoolDisplayValue(item.features[ON_SITE]),
    width: 100,
  },
  {
    name: `features.${GENERATOR}`,
    title: 'Automatic entry ID',
    getCellValue: item => getBoolDisplayValue(item.features[GENERATOR]),
    width: 100,
  },
  {
    name: `features.${DRAWING_MANAGEMENT}`,
    title: 'Drawing management',
    getCellValue: item =>
      getBoolDisplayValue(item.features[DRAWING_MANAGEMENT]),
    width: 100,
  },
  {
    name: `features.${WORKSPACES}`,
    title: 'Workspaces',
    getCellValue: item => getBoolDisplayValue(item.features[WORKSPACES]),
    width: 100,
  },
  {
    name: `features.${SHARED_USERS}`,
    title: 'Shared users',
    getCellValue: item => getBoolDisplayValue(item.features[SHARED_USERS]),
    width: 100,
  },
  {
    name: `features.${VISUAL_BOARDS}`,
    title: 'Visual boards',
    getCellValue: item => getBoolDisplayValue(item.features[VISUAL_BOARDS]),
    width: 100,
  },
  {
    name: `features.${PUBLIC_LINKS}`,
    title: 'Public links',
    getCellValue: item => getBoolDisplayValue(item.features[PUBLIC_LINKS]),
    width: 100,
  },
  {
    name: `features.${DISTRIBUTION_GROUPS}`,
    title: 'Distribution groups',
    getCellValue: item =>
      getBoolDisplayValue(item.features[DISTRIBUTION_GROUPS]),
    width: 100,
  },
  {
    name: `features.${MFA}`,
    title: 'Multi-factor authentication',
    getCellValue: item => getBoolDisplayValue(item.features[MFA]),
    width: 100,
  },
  {
    name: `features.${INTERNAL_COMMENTS}`,
    title: 'Internal comments',
    getCellValue: item => getBoolDisplayValue(item.features[INTERNAL_COMMENTS]),
    width: 100,
  },
  {
    name: `features.${CREATE_ROOM}`,
    title: 'Allow room creation',
    getCellValue: item => getBoolDisplayValue(item.features[CREATE_ROOM]),
    width: 100,
  },
  {
    name: `features.${ORDER_ROOM}`,
    title: 'Allow room order',
    getCellValue: item => getBoolDisplayValue(item.features[ORDER_ROOM]),
    width: 100,
  },
  {
    name: `features.${EXTERNAL_PUBLISH}`,
    title: 'Publish to separate folder',
    getCellValue: item => getBoolDisplayValue(item.features[EXTERNAL_PUBLISH]),
    width: 100,
  },
  {
    name: `features.${PROJECT_CONTEXT}`,
    title: 'Project context',
    getCellValue: item => getBoolDisplayValue(item.features[PROJECT_CONTEXT]),
    width: 100,
  },
];

const EditableIsTemplateCell = item => {
  return item.template ? <Check /> : '';
};
EditableIsTemplateCell.displayName = 'EditableIsTemplateCell';

export const templateRoomsColumns = [
  {
    name: TEMPLATE,
    columnName: TEMPLATE,
    title: 'Template',
    width: 150,
    customComponent: EditableIsTemplateCell,
  },
  {
    name: TITLE,
    columnName: TITLE,
    title: 'Template room name',
    width: 250,
  },
  {
    name: DESCRIPTION,
    columnName: DESCRIPTION,
    title: 'Description',
    width: 450,
  },
];

export const editableTemplateRoomsColumns = [
  {
    name: TITLE,
    columnName: TITLE,
    title: 'Template room name',
    width: 250,
    readOnly: true,
  },
  {
    name: NEW_TITLE,
    columnName: NEW_TITLE,
    title: 'New room name',
    width: 370,
  },
  {
    name: AS_TEMPLATE,
    columnName: AS_TEMPLATE,
    title: 'Create as template',
    width: 250,
    type: 'bool',
  },
  {
    name: WITH_CONTENT,
    columnName: WITH_CONTENT,
    title: 'With Content',
    width: 250,
    type: 'bool',
  },
];

export const getCustomerCellComponentRenderer = cell => {
  if (cell.column.name === SPLIT_COST) {
    return cell.row.splitCostAcrossCommunities ? (
      <div>
        <Check />
      </div>
    ) : (
      ''
    );
  }

  return cell.row[cell.column.name] || '';
};

export const getValue = (item, key) => {
  if (key.indexOf('.') !== -1) {
    const keys = key.split('.');
    const keysLength = keys.length;
    let index = 0;
    let value = item;

    while (index < keysLength) {
      if (value) {
        value = value[keys[index]];
        index++;
      } else {
        return undefined;
      }
    }

    return value;
  } else {
    return item[key];
  }
};
