import { createSelector } from 'reselect';
import Immutable from 'seamless-immutable';

export const types = {
  CHANGE_NATIVE_COMMUNITY_REQUEST: 'CHANGE_NATIVE_COMMUNITY_REQUEST',
  CHANGE_NATIVE_COMMUNITY_SUCCESS: 'CHANGE_NATIVE_COMMUNITY_SUCCESS',
  CHANGE_NATIVE_COMMUNITY_ERROR: 'CHANGE_NATIVE_COMMUNITY_ERROR',
};

export const storeIdentifier = 'members';

const initialState = Immutable({
  isLoading: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_NATIVE_COMMUNITY_REQUEST: {
      return state.merge({
        isLoading: true,
      });
    }
    case types.CHANGE_NATIVE_COMMUNITY_SUCCESS: {
      return state.merge({
        isLoading: false,
      });
    }
    case types.CHANGE_NATIVE_COMMUNITY_ERROR: {
      return state.merge({
        isLoading: false,
      });
    }
    default:
      return state;
  }
}

// Actions
export const actions = {
  changeNativeCommunityRequest: () => ({
    type: types.CHANGE_NATIVE_COMMUNITY_REQUEST,
  }),

  changeNativeCommunitySuccess: () => ({
    type: types.CHANGE_NATIVE_COMMUNITY_SUCCESS,
  }),

  changeNativeCommunityError: ({ error }) => {
    return {
      type: types.CHANGE_NATIVE_COMMUNITY_ERROR,
      payload: {
        error,
      },
    };
  },
};

// Selectors
const membersSelector = () => state => state[storeIdentifier];

export const selectIsLoading = () =>
  createSelector(membersSelector(), roomsState => roomsState.isLoading);
