import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { Auth0Provider } from '@tribiahq/interaxo-react-components';
import AUTH_CONFIG from './services/AuthConfig';
import Authentication from './Authentication';

import * as serviceWorker from './serviceWorker';

window.appInfo = {
  version: process.env.REACT_APP_VERSION,
  sha: process.env.REACT_APP_GIT_SHA,
};

const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
};

ReactDOM.render(
  <Auth0Provider
    audience={AUTH_CONFIG.audience}
    domain={AUTH_CONFIG.domain}
    client_id={AUTH_CONFIG.client_id}
    scope={AUTH_CONFIG.scope}
    redirect_uri={AUTH_CONFIG.redirect_uri}
    onRedirectCallback={onRedirectCallback}>
    <Authentication />
  </Auth0Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
