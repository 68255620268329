import { client } from './API';

export function fetchCustomers() {
  return client.get(`/api/customers`).then(response => response.data);
}

export function createCustomer({ data }) {
  return client.post(`/api/customers`, data).then(response => response.data);
}

export function updateCustomer({ customerNodeId, data }) {
  return client
    .put(`/api/customers/${customerNodeId}`, data)
    .then(response => response.data);
}
