import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Error from '../component/Error';
import MergeUsersPage from './user/MergeUsersPage';
import Customers from './customer/component/CustomersGrid';
import CustomersContainer from './customer/container/CustomersContainer';
import Customer from './customer/container/CustomerContainer';
import Communities from './community/container/CommunitiesContainer';
import Community from './community/container/CommunityContainer';
import Rooms from './rooms/container/RoomsContainer';
import ChangeCommunity from './user/ChangeCommunityContainer';

export default () => (
  <Switch>
    <Route path="/" exact component={MergeUsersPage} />
    <Route path="/customers/create" exact component={Customer} />
    <Route
      path="/customers"
      render={({ match: { url } }) => (
        <CustomersContainer>
          <Route exact path={url} component={Customers} />
          <Route exact path={`${url}/:customerId`} component={Customer} />
        </CustomersContainer>
      )}
    />
    <Route path="/communities" exact component={Communities} />
    <Route
      exact
      path={`/communities/create`}
      component={() => <Community mode={'create'} />}
    />
    <Route
      exact
      path={`/communities/:communityId`}
      component={() => <Community mode={'update'} />}
    />
    <Route
      exact
      path={`/rooms`}
      component={Rooms}
    />
    <Route
      exact
      path={`/members`}
      component={ChangeCommunity}
    />
    <Route path="*" component={Error} />
  </Switch>
);
