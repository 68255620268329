import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';

import auth from './auth';
import users, { storeIdentifier as usersStoreIdentifier } from './Users';
import customers, {
  storeIdentifier as customersStoreIdentifier,
} from './Customers';
import communities, {
  storeIdentifier as communitiesStoreIdentifier,
} from './Communities';
import tenantStores, {
  storeIdentifier as tenantStoresStoreIdentifier,
} from './TenantStores';
import rooms, { storeIdentifier as roomsStoreIdentifier } from './Rooms';
import members, { storeIdentifier as membersStoreIdentifier } from './Members';
import environmentPreferences, {
  storeIdentifier as environmentPreferencesIdentifier,
} from './EnvironmentPreferences';
import notifications from './notifications';

export default combineReducers({
  auth,
  [usersStoreIdentifier]: users,
  [customersStoreIdentifier]: customers,
  [communitiesStoreIdentifier]: communities,
  [tenantStoresStoreIdentifier]: tenantStores,
  [roomsStoreIdentifier]: rooms,
  [environmentPreferencesIdentifier]: environmentPreferences,
  [membersStoreIdentifier]: members,
  notifications,
  toastr: toastrReducer,
});
