import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { Cell, Table } from '@tribiahq/interaxo-react-components';
import { withStyles } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';

const styles = () => ({
  noData: {
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    transform: 'none',
  },
});

class CustomGrid extends React.Component {
  render() {
    const {
      columns,
      items,
      renderCellComponent,
      renderToolbarButtons,
      classes,
      editing,
      height,
    } = this.props;

    const defaultColumnWidths = columns.map(column => ({
      columnName: column.name,
      width: column.width || 250,
    }));

    return (
      <Paper>
        <Table
          columns={columns || []}
          rows={items}
          virtual
          height={height}
          columnResizing={{
            defaultColumnWidths,
          }}
          columnVisibility={{
            columnChooserConfig: {
              messages: {
                showColumnChooser: 'Show / hide columns',
              },
            },
          }}
          toolbarConfig={{
            rootComponent: ({ children }) => (
              <Toolbar>
                {renderToolbarButtons()}
                {children}
              </Toolbar>
            ),
          }}
          search={{
            panelConfig: {
              messages: {
                searchPlaceholder: 'Search...',
              },
            },
          }}
          sorting={{
            stateConfig: {
              defaultSorting: [{ columnName: 'communityId', direction: 'asc' }],
            },
          }}
          editing={{
            config: {
              cellComponent: ({ row }) => {
                return editing.renderEditButton({ row });
              },
              width: editing.width,
            },
          }}
          tableConfig={{
            // the average value for a table whose rows have different heights
            // in our case, the height of the row with details may vary depending on the number of fields in the entry
            estimatedRowHeight: 1000,
            cellComponent: obj => <Cell>{renderCellComponent(obj)}</Cell>,
            messages: {
              noData: <div className={classes.noData}>No content</div>,
            },
          }}
        />
      </Paper>
    );
  }
}

CustomGrid.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    noData: PropTypes.string,
  }),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  items: PropTypes.arrayOf(PropTypes.shape({})),
  renderCellComponent: PropTypes.func,
  renderToolbarButtons: PropTypes.func,
  editing: PropTypes.shape({
    renderEditButton: PropTypes.func,
    width: PropTypes.number,
  }),
  height: PropTypes.number,
};

CustomGrid.defaultProps = {
  classes: {},
  columns: [],
  items: [],
  renderCellComponent: () => {},
  renderToolbarButtons: () => {},
};

export default withStyles(styles)(CustomGrid);
