import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  selectNotification,
  selectNotificationEnabled,
} from '../reducers/notifications';

import * as notificationActions from '../reducers/notifications';

const DEFAULT_DURATION = 5000; // 5 seconds

class Notifications extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.props.notificationState !== nextProps.notificationState;
  }

  render() {
    const { notificationState, enabled, actions } = this.props;

    if (!enabled || notificationState === null) {
      return null;
    }

    const notification = notificationState;

    return (
      <Snackbar
        open
        message={notification.message}
        autoHideDuration={notification.duration || DEFAULT_DURATION}
        onClose={actions.hide}
      />
    );
  }
}

Notifications.propTypes = {
  notificationState: PropTypes.shape({
    message: PropTypes.string,
    params: PropTypes.object,
    duration: PropTypes.number,
  }),
  enabled: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    hide: PropTypes.func,
  }),
};

Notifications.defaultProps = {
  notificationState: null,
  enabled: false,
  actions: {},
};

const mapStateToProps = () =>
  createSelector(
    selectNotification(),
    selectNotificationEnabled(),
    (notificationState, enabled) => ({ notificationState, enabled }),
  );

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(notificationActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notifications);
