import { client } from './API';
import { escapeContent } from '../utils/SearchUtils';

export function findCommunities(term) {
  const escapedContent = escapeContent(term);
  const searchTerm = encodeURI(escapedContent);
  return client
    .get(`/api/communities`, {
      params: {
        term: searchTerm,
      },
    })
    .then(response => response.data);
}

export function fetchCommunity({ communityId }) {
  return client
    .get(`/api/communities/${communityId}`)
    .then(response => response.data);
}

export function createCommunity({ data }) {
  return client.post(`/api/communities`, data).then(response => response.data);
}

export function enableCommunity({ communityId }) {
  return client
    .post(`/api/enabled-communities`, { id: communityId })
    .then(response => response.data);
}

export function disableCommunity({ communityId }) {
  return client
    .delete(`/api/enabled-communities/${communityId}`)
    .then(response => response.data);
}

export function updateCommunity({ communityId, data }) {
  return client
    .put(`/api/communities/${communityId}`, data)
    .then(response => response.data);
}
