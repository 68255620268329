import * as api from '../services/API';
import onError from './Error';
import { actions } from '../reducers/Rooms';
import { show } from '../reducers/notifications';

export function fetchRooms({ communityId }) {
  return async dispatch => {
    dispatch(actions.fetchRoomsRequest({ communityId }));

    try {
      const rooms = await api.fetchRooms({ communityId });

      return dispatch(actions.fetchRoomsSuccess({ rooms }));
    } catch (error) {
      dispatch(
        show({ message: `No match for community ${communityId} found!` }),
      );
      return onError({
        error,
        dispatch,
        action: actions.fetchRoomsError({ error }),
      });
    }
  };
}

export function createTemplateRooms({ communityId, templateRooms }) {
  return async dispatch => {
    dispatch(actions.createTemplateRoomsRequest({ communityId }));

    try {
      await api.createTemplateRooms({ communityId, templateRooms });

      dispatch(show({ message: 'Room created successfully!' }));
      return dispatch(actions.createTemplateRoomsSuccess());
    } catch (error) {
      dispatch(show({ message: 'Application error occurred!' }));
      return onError({
        error,
        dispatch,
        action: actions.createTemplateRoomsError({ error }),
      });
    }
  };
}
