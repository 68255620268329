import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Form from '../component/Form';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Community } from '../../../models/Community';
import {
  clearActiveCommunity,
  selectActiveCommunity,
  selectIsActiveCommunityLoading,
  selectIsActiveCommunityRequested,
  selectIsEnableLoading,
  selectIsDisableLoading
} from '../../../reducers/Communities';
import { selectTenantStores } from '../../../reducers/TenantStores';
import { createStructuredSelector } from 'reselect';
import * as communitiesActions from '../../../thunks/Communities';
import * as tenantStoresActions from '../../../thunks/TenantStores';
import {
  createCommunityValidationSchema,
  updateCommunityValidationSchema,
} from '../../../utils/validation/Validators';
import {
  createCommunityFeaturesFieldsConfig,
  createCommunityFieldsConfig,
  updateCommunityFeaturesFieldsConfig,
  updateCommunityFieldsConfig,
} from '../../../utils/FieldsUtil';
import LinearProgress from '@material-ui/core/LinearProgress';
import { renderCustomSelectField } from '../../../utils/RenderUtil';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { PriorityHigh } from '@material-ui/icons';

const styles = theme => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    display: 'flex',
    flexWrap: 'wrap',
    height: '50%',
  },
  icon: {
    height: 45,
    width: 45,
    marginTop: 5,
    color: '#f44336',
  },
  formControl: {
    width: '80%',
  },
  rightSide: {
    width: '25%',
    minWidth: '300px',
    marginRight: '30px',
  },
  container: {
    marginTop: 10,
  },
  typography: {
    marginLeft: 40,
  },
  divider: {
    marginRight: '2%',
  },
});

const mapStateToProps = (state, ownParams) =>
  createStructuredSelector({
    initialValues: selectActiveCommunity(ownParams.match.params.communityId),
    isLoading: selectIsActiveCommunityLoading(),
    isRequested: selectIsActiveCommunityRequested(),
    tenantStores: selectTenantStores(),
    isEnableLoading: selectIsEnableLoading(),
    isDisableLoading: selectIsDisableLoading(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { ...communitiesActions, ...tenantStoresActions, clearActiveCommunity },
    dispatch,
  ),
});

class CommunityContainer extends React.Component {
  componentDidMount() {
    const { initialValues, match, actions } = this.props;

    if (this.isCreate()) {
      actions.fetchTenantStores();
    } else {
      const shouldFetch =
        !this.props.isLoading &&
        (!this.props.isRequested || 
        initialValues.communityId !== match.params.communityId);

      if (shouldFetch) {
        actions.fetchCommunity({
          communityId: match.params.communityId,
        });
      }
    }
  }

  handleCreateSubmit = values => {
    const data = Object.fromEntries(
      Object.entries(values).filter(
        entry => entry[0] !== 'confirmPassword' && entry[0] !== 'confirmSecret',
      ),
    );

    return this.props.actions.createCommunity({ data }).then(action => {
      if (!action.payload.error) {
        this.props.history.push(
          `/communities/${action.payload.community.communityId}`,
        );
      }
    });
  };

  handleUpdateSubmit = values =>
    this.props.actions.updateCommunity({
      communityId: values.communityId,
      data: values,
    });

  handleCancel = () => {
    this.props.history.push(`/communities`);
  };

  isCreate = () => this.props.mode === 'create';

  renderCustomPanel = ({
    errors,
    values,
    handleChange,
    handleBlur,
    touched,
  }) => {
    const { classes, initialValues } = this.props;
    return (
      <React.Fragment>
        <div className={classes.divider}>
          <Divider orientation="vertical" />
        </div>
        <div className={classes.rightSide}>
          {this.isCreate() ? <div className={classes.container}>
            <Typography className={classes.typography}>
              Choose where the community will be stored
            </Typography>
            <div className={classes.container}>
              <PriorityHigh className={classes.icon} />
              {this.renderStorageSelector({
                values,
                errors,
                handleChange,
                handleBlur,
                touched,
              })}
            </div>
          </div> : <div className={classes.container}>
              <FormControlLabel
                control={
                  <Switch 
                    checked={initialValues.enabled}
                    name={'enabled'}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    onChange={this.handleEnabledChanged}
                  />
                }
                label={initialValues.enabled ? 'Enabled' : 'Disabled'}
                className={classes.field}
              />
            </div>}
        </div>
      </React.Fragment>
    );
  };

  renderStorageSelector = ({
    errors,
    values,
    handleChange,
    handleBlur,
    touched,
  }) => {
    const { tenantStores, classes } = this.props;
    let fieldConfig = createCommunityFieldsConfig.find(
      value => value.valuesKey === 'tenantStore',
    );

    return renderCustomSelectField({
      key: fieldConfig.valuesKey,
      name: fieldConfig.valuesKey,
      label: fieldConfig.label,
      labelId: fieldConfig.valuesKey,
      value: values[fieldConfig.valuesKey],
      handleChange: handleChange,
      handleBlur: handleBlur,
      required: fieldConfig.required && fieldConfig.required(values),
      disabled: fieldConfig.disabled && fieldConfig.disabled(values),
      error: fieldConfig.error && fieldConfig.error(touched, errors),
      helperText: errors[fieldConfig.valuesKey],
      options: tenantStores || [],
      className: classes.formControl,
    });
  };

  handleEnabledChanged = () => {
    this.props.initialValues.enabled
      ? this.props.actions.disableCommunity({ communityId: this.props.initialValues.communityId })
      : this.props.actions.enableCommunity({ communityId: this.props.initialValues.communityId })
  }

  render() {
    const { classes, initialValues, isLoading, isRequested, isEnableLoading, isDisableLoading } = this.props;

    // Loading indicator required for the case of Update mode
    // when either we already requested to fetch community
    // or we not yet requested but will
    if (
      !this.isCreate() &&
      ((isLoading && isRequested) || (!isLoading && !isRequested) || isEnableLoading || isDisableLoading)
    ) {
      return <LinearProgress />;
    }

    return (
      <Paper className={classes.root}>
        <Form
          fieldsConfig={
            this.isCreate()
              ? createCommunityFieldsConfig
              : updateCommunityFieldsConfig
          }
          featuresFieldsConfig={
            this.isCreate()
              ? createCommunityFeaturesFieldsConfig
              : updateCommunityFeaturesFieldsConfig
          }
          initialValues={initialValues}
          handleSubmit={
            this.isCreate() ? this.handleCreateSubmit : this.handleUpdateSubmit
          }
          handleCancel={() => this.handleCancel()}
          validationSchema={
            this.isCreate()
              ? createCommunityValidationSchema
              : updateCommunityValidationSchema
          }
          renderAdditionalPanel={ this.renderCustomPanel }
        />
      </Paper>
    );
  }
}

CommunityContainer.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    rightSide: PropTypes.string,
    icon: PropTypes.string,
    formControl: PropTypes.string,
    container: PropTypes.string,
    typography: PropTypes.string,
    divider: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      communityId: PropTypes.string,
    }),
  }),
  mode: PropTypes.string,
  initialValues: PropTypes.shape({
    communityId: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  actions: PropTypes.shape({
    createCommunity: PropTypes.func,
    updateCommunity: PropTypes.func,
    clearActiveCommunity: PropTypes.func,
    fetchCommunity: PropTypes.func,
    fetchTenantStores: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
  isRequested: PropTypes.bool,
  tenantStores: PropTypes.arrayOf(PropTypes.string),
};

CommunityContainer.defaultProps = {
  history: {},
  initialValues: Community.defaultValue,
  isLoading: false,
  isRequested: false,
  tenantStores: [],
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(styles)(CommunityContainer)),
);
