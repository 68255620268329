import * as api from '../services/API';
import onError from './Error';
import { actions } from '../reducers/TenantStores';
import { show } from '../reducers/notifications';

export function fetchTenantStores() {
  return async dispatch => {
    dispatch(actions.fetchTenantStoresRequest());

    try {
      const tenantStores = await api.fetchTenantStores();

      return dispatch(actions.fetchTenantStoresSuccess({ tenantStores }));
    } catch (error) {
      dispatch(show({ message: 'Application error occurred!' }));
      return onError({
        error,
        dispatch,
        action: actions.fetchTenantStoresError({ error }),
      });
    }
  };
}
