// Redux
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from '../reducers';

export default () => {
  const middlewares = [thunk];
  const store = createStore(reducer, compose(applyMiddleware(...middlewares)));
  return store;
};
