import * as api from '../services/API';
import onError from './Error';
import { actions } from '../reducers/Communities';
import { show } from '../reducers/notifications';

export function findCommunities({ term }) {
  return async dispatch => {
    dispatch(actions.findCommunitiesRequest());

    try {
      const communities = await api.findCommunities(term);

      return dispatch(
        actions.findCommunitiesSuccess({ communities, searchTerm: term }),
      );
    } catch (error) {
      dispatch(show({ message: 'Application error occurred!' }));
      return onError({
        error,
        dispatch,
        action: actions.findCommunitiesError({ error }),
      });
    }
  };
}

export function fetchCommunity({ communityId }) {
  return async dispatch => {
    dispatch(actions.fetchCommunityRequest({ communityId }));

    try {
      const community = await api.fetchCommunity({ communityId });

      return dispatch(actions.fetchCommunitySuccess({ community }));
    } catch (error) {
      dispatch(show({ message: 'Application error occurred!' }));
      return onError({
        error,
        dispatch,
        action: actions.fetchCommunityError({ error }),
      });
    }
  };
}

export function createCommunity({ data }) {
  return async dispatch => {
    dispatch(actions.createCommunityRequest());

    try {
      // response doesn't contain newly created community
      await api.createCommunity({ data });
      // todo: remove if response contains newly created community
      // remove agreement id since it was applied to standard workspace
      if (data.invoiceLevel === 'Workspace') {
        data.agreementId = '';
      }

      dispatch(show({ message: 'Community successfully created!' }));
      return dispatch(actions.createCommunitySuccess({ community: data }));
    } catch (error) {
      dispatch(show({ message: 'Application error occurred!' }));
      return onError({
        error,
        dispatch,
        action: actions.createCommunityError({ error }),
      });
    }
  };
}

export function updateCommunity({ communityId, data }) {
  return async dispatch => {
    dispatch(actions.updateCommunityRequest());

    try {
      const community = await api.updateCommunity({ communityId, data });

      dispatch(show({ message: 'Community successfully updated!' }));
      return dispatch(actions.updateCommunitySuccess({ community }));
    } catch (error) {
      dispatch(show({ message: 'Application error occurred!' }));
      return onError({
        error,
        dispatch,
        action: actions.updateCommunityError({ error }),
      });
    }
  };
}

export function enableCommunity({ communityId }) {
  return async dispatch => {
    dispatch(actions.enableCommunityRequest({ communityId }));

    try {
      // response doesn't contain newly created community
      await api.enableCommunity({ communityId });

      dispatch(show({ message: 'Community successfully enabled!' }));
      return dispatch(actions.enableCommunitySuccess({ communityId }));
    } catch (error) {
      dispatch(show({ message: 'Application error occurred!' }));
      return onError({
        error,
        dispatch,
        action: actions.enableCommunityError({ error }),
      });
    }
  };
}

export function disableCommunity({ communityId }) {
  return async dispatch => {
    dispatch(actions.disableCommunityRequest({ communityId }));

    try {
      // response doesn't contain newly created community
      await api.disableCommunity({ communityId });

      dispatch(show({ message: 'Community successfully disabled!' }));
      return dispatch(actions.disableCommunitySuccess({ communityId }));
    } catch (error) {
      dispatch(show({ message: 'Application error occurred!' }));
      return onError({
        error,
        dispatch,
        action: actions.disableCommunityError({ error }),
      });
    }
  };
}
