import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const ENABLE_NOTIFICATIONS = 'ENABLE_NOTIFICATIONS';
export const DISABLE_NOTIFICATIONS = 'DISABLE_NOTIFICATIONS';

const initialState = Immutable({
  notification: null,
  enabled: true,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return state.merge({
        notification: action.payload,
      });
    case HIDE_NOTIFICATION:
      return state.merge({
        notification: null,
      });
    case ENABLE_NOTIFICATIONS:
      return state.merge({
        enabled: true,
      });
    case DISABLE_NOTIFICATIONS:
      return state.merge({
        enabled: false,
      });
    default:
      return state;
  }
}

export const show = ({ message, params }) => ({
  type: SHOW_NOTIFICATION,
  payload: { message, params: params || {} },
});

export const hide = () => ({
  type: HIDE_NOTIFICATION,
});

export const enable = () => ({
  type: ENABLE_NOTIFICATIONS,
});

export const disable = () => ({
  type: DISABLE_NOTIFICATIONS,
});

const notificationsSelector = () => state => state.notifications;

export const selectNotificationState = () =>
  createSelector(
    notificationsSelector(),
    notificationState => notificationState,
  );

export const selectNotification = () =>
  createSelector(
    notificationsSelector(),
    notificationState => notificationState.notification,
  );

export const selectNotificationEnabled = () =>
  createSelector(
    notificationsSelector(),
    notificationState => notificationState.enabled,
  );
