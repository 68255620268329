import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

export const CellEditor = ({
  onValueChange,
  record,
  fieldName,
  disabled,
  type,
  validators,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');

  const handleSubmitValue = newValue => {
    onValueChange(error ? record[fieldName] : newValue);
    setIsEditing(false);
  };

  const handleEditClick = () => setIsEditing(true);

  const handleOnKeyPress = event => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmitValue(event.target.value);
    }
  };

  if (type === 'bool') {
    return (
      <Checkbox
        id={`${fieldName}-${record}`}
        defaultChecked={record[fieldName]}
        name={'splitCostAcrossCommunities'}
        color="primary"
        disabled={disabled}
        onChange={event => {
          handleSubmitValue(event.target.checked);
        }}
      />
    );
  }

  return isEditing && !disabled ? (
    <TextField
      style={{ height: 40, width: 200 }}
      defaultValue={record[fieldName]}
      id={`${fieldName}-${record}`}
      onKeyPress={handleOnKeyPress}
      onBlur={event => handleSubmitValue(event.target.value)}
      onChange={event => {
        setError(
          validators?.find(v => !v.isValid(event.target.value))?.errorText,
        );
      }}
      autoFocus
      error={!!error}
      helperText={error}
    />
  ) : (
    <span
      onClick={handleEditClick}
      style={{
        height: 40,
        lineHeight: '40px',
        display: 'inline-block',
        width: '100%',
      }}>
      {record[fieldName]}
    </span>
  );
};

CellEditor.propTypes = {
  onValueChange: PropTypes.func,
  record: PropTypes.object,
  fieldName: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  validators: PropTypes.arrayOf(
    PropTypes.shape({
      isValid: PropTypes.func,
      errorMessage: PropTypes.string,
    }),
  ),
};

CellEditor.defaultProps = {
  validators: [],
};
