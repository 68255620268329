import { useSelector } from 'react-redux';
import {
  selectIsCreateLoadingTemplateRooms,
  selectIsCreateRequestedTemplateRooms,
} from '../reducers/Rooms';

const useTemplateRooms = () => {
  const isRequested = useSelector(selectIsCreateRequestedTemplateRooms());
  const isLoading = useSelector(selectIsCreateLoadingTemplateRooms());

  return {
    isRequested,
    isLoading,
  };
};

export default useTemplateRooms;
