import { useSelector } from 'react-redux';
import {
  selectRooms,
  selectIsLoadingRooms,
  selectIsRequestedRooms,
} from '../reducers/Rooms';

const useRooms = () => {
  const isRequested = useSelector(selectIsRequestedRooms());
  const isLoading = useSelector(selectIsLoadingRooms());
  const rooms = useSelector(selectRooms());

  return {
    isRequested,
    isLoading,
    rooms,
  };
};

export default useRooms;
