import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid/Grid';
import Form from '../component/Form';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';
import { Customer } from '../../../models/Customer';
import { selectCustomer } from '../../../reducers/Customers';
import { createStructuredSelector } from 'reselect';
import * as customersActions from '../../../thunks/Customers';

const styles = theme => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    display: 'flex',
    flexWrap: 'wrap',
    height: '50%',
  },
});

const mapStateToProps = (state, ownProp) =>
  createStructuredSelector({
    initialValues: selectCustomer(ownProp.match.params.customerId),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...customersActions }, dispatch),
});

class CustomerContainer extends React.Component {
  handleSubmit = ({ values }) => {
    const { actions, history } = this.props;

    if (!values.nodeId) {
      return actions.createCustomer({ data: values }).then(action => {
        if (!action.payload.error) {
          history.push(`/customers/${action.payload.customer.nodeId}`);
        }
      });
    } else {
      return actions.updateCustomer({ customerNodeId: values.nodeId, data: values });
    }
  };

  handleCancel = () => {
    this.props.history.push(`/customers`);
  };

  render() {
    const { classes, initialValues } = this.props;

    return (
      <Paper className={classes.root}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={8} xs={12}>
            <Form
              initialValues={initialValues}
              handleSubmit={this.handleSubmit}
              handleCancel={() => this.handleCancel()}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

CustomerContainer.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      customerId: PropTypes.string,
    }),
  }),
  initialValues: PropTypes.shape({}),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  actions: PropTypes.shape({
    createCustomer: PropTypes.func,
    updateCustomer: PropTypes.func,
  }),
};

CustomerContainer.defaultProps = {
  history: {},
  initialValues: Customer.defaultValue,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(styles)(CustomerContainer)),
);
