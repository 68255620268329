import { createSelector } from 'reselect';
import Immutable from 'seamless-immutable';

export const types = {
  FETCH_ROOMS_REQUEST: 'FETCH_ROOMS_REQUEST',
  FETCH_ROOMS_SUCCESS: 'FETCH_ROOMS_SUCCESS',
  FETCH_ROOMS_ERROR: 'FETCH_ROOMS_ERROR',

  CREATE_TEMPLATE_ROOMS_REQUEST: 'CREATE_TEMPLATE_ROOMS_REQUEST',
  CREATE_TEMPLATE_ROOMS_SUCCESS: 'CREATE_TEMPLATE_ROOMS_SUCCESS',
  CREATE_TEMPLATE_ROOMS_ERROR: 'CREATE_TEMPLATE_ROOMS_ERROR',

  CLEAR_TEMPLATE_ROOMS: 'CLEAR_TEMPLATE_ROOMS',
};

export const storeIdentifier = 'rooms';

const initialState = Immutable({
  rooms: [],
  requested: false,
  isLoading: false,
  createRequested: false,
  createIsLoading: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ROOMS_REQUEST: {
      return state.merge({
        requested: { communityId: action.payload.communityId },
        isLoading: true,
      });
    }
    case types.FETCH_ROOMS_SUCCESS: {
      return state.merge({
        isLoading: false,
        rooms: action.payload.rooms,
      });
    }
    case types.FETCH_ROOMS_ERROR: {
      return state.merge({
        isLoading: false,
      });
    }
    case types.CREATE_TEMPLATE_ROOMS_REQUEST: {
      return state.merge({
        createRequested: { communityId: action.payload.communityId },
        createIsLoading: true,
      });
    }
    case types.CREATE_TEMPLATE_ROOMS_SUCCESS: {
      return state.merge({
        createIsLoading: false,
      });
    }
    case types.CREATE_TEMPLATE_ROOMS_ERROR: {
      return state.merge({
        createIsLoading: false,
      });
    }
    case types.CLEAR_TEMPLATE_ROOMS: {
      return state.merge({
        rooms: [],
        requested: false,
      });
    }
    default:
      return state;
  }
}

// Actions
export const actions = {
  fetchRoomsRequest: ({ communityId }) => ({
    type: types.FETCH_ROOMS_REQUEST,
    payload: { communityId },
  }),

  fetchRoomsSuccess: ({ rooms }) => ({
    type: types.FETCH_ROOMS_SUCCESS,
    payload: { rooms },
  }),

  fetchRoomsError: ({ error }) => {
    return {
      type: types.FETCH_ROOMS_ERROR,
      payload: {
        error,
      },
    };
  },

  createTemplateRoomsRequest: ({ communityId }) => ({
    type: types.CREATE_TEMPLATE_ROOMS_REQUEST,
    payload: { communityId },
  }),

  createTemplateRoomsSuccess: () => ({
    type: types.CREATE_TEMPLATE_ROOMS_SUCCESS,
  }),

  createTemplateRoomsError: ({ error }) => ({
    type: types.CREATE_TEMPLATE_ROOMS_ERROR,
    payload: {
      error,
    },
  }),

  clearRooms: () => ({
    type: types.CLEAR_TEMPLATE_ROOMS,
  }),
};

// Selectors
const roomsSelector = () => state => state[storeIdentifier];

export const selectIsLoadingRooms = () =>
  createSelector(roomsSelector(), roomsState => roomsState.isLoading);

export const selectIsRequestedRooms = () =>
  createSelector(roomsSelector(), roomsState => roomsState.requested);

export const selectRooms = () =>
  createSelector(roomsSelector(), roomsState => roomsState.rooms);

export const selectIsCreateLoadingTemplateRooms = () =>
  createSelector(
    roomsSelector(),
    templateRoomsState => templateRoomsState.createIsLoading,
  );

export const selectIsCreateRequestedTemplateRooms = () =>
  createSelector(
    roomsSelector(),
    templateRoomsState => templateRoomsState.createRequested,
  );
