import { createSelector } from 'reselect';
import Immutable from 'seamless-immutable';

// Constants

export const types = {
  MERGE_USERS_REQUEST: 'MERGE_USERS_REQUEST',
  MERGE_USERS_SUCCESS: 'MERGE_USERS_SUCCESS',
  MERGE_USERS_ERROR: 'MERGE_USERS_ERROR',
};

export const storeIdentifier = 'users';

const initialState = Immutable({
  requested: false,
  isLoading: false,
  mergeUsersResponse: {},
});

// Reducer

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.MERGE_USERS_REQUEST: {
      return state.merge({
        requested: true,
        isLoading: true,
      });
    }
    case types.MERGE_USERS_SUCCESS: {
      return state.merge({
        isLoading: false,
        mergeUsersResponse: action.payload.mergeUsersResponse,
      });
    }
    case types.MERGE_USERS_ERROR: {
      return state.merge({
        isLoading: false,
      });
    }
    default:
      return state;
  }
}

// Actions

export const actions = {
  mergeUsersRequest: () => ({
    type: types.MERGE_USERS_REQUEST,
  }),

  mergeUsersSuccess: ({ mergeUsersResponse }) => ({
    type: types.MERGE_USERS_SUCCESS,
    payload: { mergeUsersResponse },
  }),

  mergeUsersError: ({ error }) => ({
    type: types.MERGE_USERS_ERROR,
    payload: {
      error,
    },
  }),
};

// Selectors

const usersSelector = () => state => state[storeIdentifier];

export const selectIsMergeUsersLoading = () =>
  createSelector(
    usersSelector(),
    usersSelectorState => usersSelectorState.isLoading,
  );

export const selectMergeUsersRequested = () =>
  createSelector(
    usersSelector(),
    usersSelectorState => usersSelectorState.requested,
  );

export const selectMergeUsersResponse = () =>
  createSelector(
    usersSelector(),
    usersSelectorState => usersSelectorState.mergeUsersResponse,
  );
