import * as api from '../services/API';
import onError from './Error';
import { actions } from '../reducers/EnvironmentPreferences';
import { show } from '../reducers/notifications';

export function fetchEnvironmentPreferences() {
  return async dispatch => {
    dispatch(actions.fetchEnvironmentPreferencesRequest());

    try {
      const environmentPreferences = await api.fetchEnvironmentPreferences();

      return dispatch(
        actions.fetchEnvironmentPreferencesSuccess({ environmentPreferences }),
      );
    } catch (error) {
      dispatch(show({ message: 'Application error occurred!' }));
      return onError({
        error,
        dispatch,
        action: actions.fetchEnvironmentPreferencesError({ error }),
      });
    }
  };
}
