import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';

import Button from '@material-ui/core/Button';
import { Button as SpinnerButton } from '@tribiahq/interaxo-react-components';
import { ExpansionPanelActions } from '@material-ui/core';
import { Community } from '../../../models/Community';
import {
  renderCheckboxField,
  renderSelectField,
  renderTextField,
} from '../../../utils/RenderUtil';

const styles = () => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  field: {
    width: '100%',
    marginBottom: 10,
  },
  side: {
    width: '45%',
    minWidth: '300px',
    marginRight: '30px',
  },
  rightSide: {
    width: '20%',
    minWidth: '300px',
    marginRight: '30px',
  },
  icon: {
    height: 45,
    width: 45,
    marginTop: 10,
    color: '#f44336',
  },
  formControl: {
    width: '75%',
  },
});

class Form extends React.Component {
  handleSubmit = values => this.props.handleSubmit({ values });

  listField = ({
    fieldConfig,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    className,
  }) => {
    return [
      renderSelectField({
        key: fieldConfig.valuesKey,
        name: fieldConfig.valuesKey,
        label: fieldConfig.label,
        value: values[fieldConfig.valuesKey],
        handleChange: handleChange,
        handleBlur: handleBlur,
        required: fieldConfig.required && fieldConfig.required(values),
        disabled: fieldConfig.disabled && fieldConfig.disabled(values),
        error: fieldConfig.error && fieldConfig.error(touched, errors),
        helperText: errors[fieldConfig.valuesKey],
        className: className,
        options: fieldConfig.options || [],
      }),
    ];
  };

  textField = ({
    fieldConfig,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    className,
  }) => {
    return renderTextField({
      key: fieldConfig.valuesKey,
      name: fieldConfig.valuesKey,
      label: fieldConfig.label,
      value: values[fieldConfig.valuesKey],
      handleChange: handleChange,
      handleBlur: handleBlur,
      required: fieldConfig.required && fieldConfig.required(values),
      disabled: fieldConfig.disabled && fieldConfig.disabled(values),
      error: fieldConfig.error && fieldConfig.error(touched, errors),
      helperText: errors[fieldConfig.valuesKey],
      className: className,
    });
  };

  checkboxField = ({ key, fieldConfig, values, handleChange, className }) => {
    return renderCheckboxField({
      key: key,
      name: key,
      handleChange: handleChange,
      checked: values.features[fieldConfig.valuesKey],
      label: fieldConfig.label,
      className: className,
    });
  };

  renderFieldMap = {
    select: this.listField,
    text: this.textField,
    checkbox: this.checkboxField,
  };

  buildFormItems = ({ errors, values, handleChange, handleBlur, touched }) => {
    const { fieldsConfig, classes } = this.props;
    return fieldsConfig.map(fieldConfig =>
      !fieldConfig.custom
        ? this.renderFieldMap[fieldConfig.type]({
            fieldConfig,
            values,
            handleChange,
            handleBlur,
            touched,
            errors,
            className: classes.field,
          })
        : null,
    );
  };

  buildFeatures = ({ values, handleChange, setFieldValue }) => {
    const { featuresFieldsConfig, classes } = this.props;
    return featuresFieldsConfig.map(fieldConfig =>
      this.checkboxField({
        key: `features.${fieldConfig.valuesKey}`,
        fieldConfig,
        values,
        handleChange: fieldConfig.onChange
          ? fieldConfig.onChange(setFieldValue)
          : handleChange,
        className: classes.field,
      }),
    );
  };

  render() {
    const {
      initialValues,
      handleCancel,
      handleSubmit,
      classes,
      validationSchema,
      renderAdditionalPanel,
    } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          handleSubmit(values).then(() => {
            actions.setSubmitting(false);
            actions.resetForm(values);
          });
        }}
        validationSchema={validationSchema}
        render={({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          isSubmitting,
          isValid,
          touched,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={classes.root}>
                <div className={classes.side}>
                  {this.buildFormItems({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    touched,
                  })}
                </div>
                <div
                  className={
                    renderAdditionalPanel ? classes.rightSide : classes.side
                  }>
                  {this.buildFeatures({ values, handleChange, setFieldValue })}
                </div>
                {renderAdditionalPanel &&
                  renderAdditionalPanel({
                    errors,
                    values,
                    handleChange,
                    handleBlur,
                    touched,
                  })}
              </div>

              <ExpansionPanelActions className={classes.panel}>
                {!isSubmitting && (
                  <Button size="small" onClick={handleCancel}>
                    {'Back'}
                  </Button>
                )}
                <SpinnerButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!isValid}
                  spinner={isSubmitting}>
                  {'Save'}
                </SpinnerButton>
              </ExpansionPanelActions>
            </form>
          );
        }}
      />
    );
  }
}

Form.propTypes = {
  initialValues: PropTypes.shape({}),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  classes: PropTypes.shape({
    field: PropTypes.string,
    panel: PropTypes.string,
    side: PropTypes.string,
    rightSide: PropTypes.string,
    root: PropTypes.string,
  }).isRequired,
  validationSchema: PropTypes.shape({}),
  fieldsConfig: PropTypes.arrayOf(PropTypes.shape({})),
  featuresFieldsConfig: PropTypes.arrayOf(PropTypes.shape({})),
  renderAdditionalPanel: PropTypes.func,
};

Form.defaultProps = {
  initialValues: Community.defaultValue,
  handleSubmit: () => {},
  handleCancel: () => {},
  validationSchema: {},
  fieldsConfig: [],
  featuresFieldsConfig: [],
  renderAdditionalPanel: undefined,
};

export default withStyles(styles, { withTheme: true })(Form);
