import * as yup from 'yup';

const VALID_CONTRACT_REGEX = /^[a-z0-9]+$/;
const PASSWORD_REGEXS = [/[a-z]/, /[A-Z]/, /\d/];

export const validateContract = value => VALID_CONTRACT_REGEX.test(value);

export const validateRequiredFields = (values, requiredFields) => {
  const errors = {};
  const fields = values;

  requiredFields.forEach(field => {
    if (!fields[field]) {
      errors[field] = 'This field is required.';
    }
  });

  return errors;
};

// eslint-disable-next-line no-useless-escape
export const communityIdValidator = value => !/[^a-zA-Z0-9\-\.\&]/i.test(value);

export const nodeNameValidator = value =>
  // eslint-disable-next-line no-useless-escape
  !/[^a-za-z-æ-ø-åäöа-я-ё0-9_\-\s\+\(\)&\.,]/i.test(value) &&
  !/^\s+$/.test(value);

export const COMMMUNITY_ID_VALIDATION_MESSAGE =
  'Community ID cannot contain special characters. You can use letters, numbers, hyphens and dots';

export const NODE_NAME_VALIDATION_MESSAGE =
  'This field cannot hold certain special characters, such as: ! @ # $ % ^ * { } [ ] ; : \' " \\ | < > / ?';

export const createCommunityValidationSchema = yup.object().shape({
  customerNumber: yup
    .string()
    .label('Customer Number')
    .required()
    .test(
      'customer-number-validation',
      NODE_NAME_VALIDATION_MESSAGE,
      nodeNameValidator,
    ),
  supplierId: yup
    .string()
    .label('Supplier ID')
    .required()
    .test(
      'supplier-id-validation',
      NODE_NAME_VALIDATION_MESSAGE,
      nodeNameValidator,
    ),
  communityId: yup
    .string()
    .label('Community ID')
    .required()
    .test(
      'community-id-validation',
      COMMMUNITY_ID_VALIDATION_MESSAGE,
      communityIdValidator,
    ),
  communityName: yup
    .string()
    .label('Community Name')
    .required()
    .test(
      'community-name-validation',
      NODE_NAME_VALIDATION_MESSAGE,
      nodeNameValidator,
    ),
  invoiceLevel: yup
    .string()
    .label('Invoice level')
    .required(),
  project: yup
    .string()
    .label('Project')
    .test(
      'project-validation',
      NODE_NAME_VALIDATION_MESSAGE,
      nodeNameValidator,
    ),
  salesContactName: yup
    .string()
    .nullable()
    .label('Sales contact name'),
  salesContactEmail: yup
    .string()
    .email()
    .nullable()
    .label('Sales contact email'),
  customerContactName: yup
    .string()
    .nullable()
    .label('Customer contact name'),
  customerContactEmail: yup
    .string()
    .email()
    .nullable()
    .label('Customer contact email'),
  password: yup
    .string()
    .label('Password')
    .required()
    .test('password', 'This password is weak', function(value) {
      return (
        value &&
        value.length >= 8 &&
        PASSWORD_REGEXS.every(pattern => pattern.test(value))
      );
    }),
  confirmPassword: yup
    .string()
    .required()
    .label('It')
    .test('passwords-match', 'Passwords must match', function(value) {
      return this.parent.password === value;
    }),
  secret: yup
    .string()
    .label('Secret')
    .required(),
  confirmSecret: yup
    .string()
    .required()
    .label('It')
    .test('secret-match', 'Secrets must match', function(value) {
      return this.parent.secret === value;
    }),
  tenantStore: yup
    .string()
    .required()
    .label('It'),
});

export const updateCommunityValidationSchema = yup.object().shape({
  customerNumber: yup
    .string()
    .label('Customer Number')
    .required()
    .test(
      'customer-number-validation',
      NODE_NAME_VALIDATION_MESSAGE,
      nodeNameValidator,
    ),
  supplierId: yup
    .string()
    .label('Supplier ID')
    .required()
    .test(
      'supplier-id-validation',
      NODE_NAME_VALIDATION_MESSAGE,
      nodeNameValidator,
    ),
  communityId: yup
    .string()
    .label('Community ID')
    .required()
    .test(
      'community-id-validation',
      COMMMUNITY_ID_VALIDATION_MESSAGE,
      communityIdValidator,
    ),
  communityName: yup
    .string()
    .label('Community Name')
    .required()
    .test(
      'community-name-validation',
      NODE_NAME_VALIDATION_MESSAGE,
      nodeNameValidator,
    ),
  invoiceLevel: yup
    .string()
    .label('Invoice level')
    .required(),
  project: yup
    .string()
    .label('Project')
    .test(
      'project-validation',
      NODE_NAME_VALIDATION_MESSAGE,
      nodeNameValidator,
    ),
  salesContactName: yup
    .string()
    .nullable()
    .label('Sales contact name'),
  salesContactEmail: yup
    .string()
    .email()
    .nullable()
    .label('Sales contact email'),
  customerContactName: yup
    .string()
    .nullable()
    .label('Customer contact name'),
  customerContactEmail: yup
    .string()
    .email()
    .nullable()
    .label('Customer contact email'),
});
