import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import { Button as SpinnerButton } from '@tribiahq/interaxo-react-components';
import { ExpansionPanelActions } from '@material-ui/core';
import { Customer } from '../../../models/Customer';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { validateContract } from '../../../utils/validation/Validators';

const styles = () => ({
  field: {
    width: '50%',
    marginBottom: 10,
  },
  panel: {
    marginRight: '50%',
  },
});

class Form extends React.Component {
  handleSubmit = values => {
    return this.props.handleSubmit({ values });
  };

  validate = values => {
    const errors = {};
    const requiredFields = ['customerNumber', 'customerName', 'supplierId'];
    const fields = values;

    requiredFields.forEach(field => {
      if (!fields[field]) {
        errors[field] = 'This field is required.';
      }
    });

    if (fields.contractId && !validateContract(fields.contractId)) {
      errors.contractId = 'Contract ID can only contain letters and numbers.';
    }

    return errors;
  };

  render() {
    const { initialValues, handleCancel, classes } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          this.handleSubmit(values).then(() => {
            actions.setSubmitting(false);
            actions.resetForm(values);
          });
        }}
        validate={this.validate}
        render={({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          isSubmitting,
          isValid,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              name={'customerNumber'}
              label={'Customer Number'}
              value={values.customerNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              error={touched.customerNumber && errors.customerNumber}
              helperText={errors.customerNumber}
              className={classes.field}
            />
            <br />
            <TextField
              name={'customerName'}
              label={'Customer Name'}
              value={values.customerName}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              error={touched.customerName && errors.customerName}
              helperText={errors.customerName}
              className={classes.field}
            />
            <br />
            <TextField
              name={'supplierId'}
              label={'Supplier ID'}
              value={values.supplierId}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              error={touched.supplierId && errors.supplierId}
              helperText={errors.supplierId}
              className={classes.field}
            />
            <br />
            <TextField
              name={'nodeId'}
              label={'Node ID'}
              value={values.nodeId}
              className={classes.field}
              disabled
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  name={'splitCostAcrossCommunities'}
                  color="primary"
                  onChange={handleChange}
                  checked={values.splitCostAcrossCommunities}
                />
              }
              label={'Split member cost across communities'}
              className={classes.field}
            />
            <br />
            <TextField
              name={'contractId'}
              label={'Contract ID'}
              value={values.contractId}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.contractId}
              helperText={errors.contractId}
              className={classes.field}
            />
            <br />
            <ExpansionPanelActions className={classes.panel}>
              {!isSubmitting && (
                <Button size="small" onClick={handleCancel}>
                  {'Back'}
                </Button>
              )}
              <SpinnerButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isValid}
                spinner={isSubmitting}>
                {'Save'}
              </SpinnerButton>
            </ExpansionPanelActions>
          </form>
        )}
      />
    );
  }
}

Form.propTypes = {
  initialValues: PropTypes.shape({}),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  classes: PropTypes.shape({
    field: PropTypes.string,
    panel: PropTypes.string,
  }).isRequired,
};

Form.defaultProps = {
  initialValues: Customer.defaultValue,
  handleSubmit: () => {},
  handleCancel: () => {},
};

export default withStyles(styles, { withTheme: true })(Form);
