import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as customersActions from '../../../thunks/Customers';
import {
  customersColumns,
  getCustomerCellComponentRenderer,
} from '../../../utils/ListViewUtil';
import { withStyles, Button } from '@material-ui/core';
import CustomGrid from '../../../component/CustomGrid';
import Paper from '@material-ui/core/Paper';
import IconEdit from '@material-ui/icons/EditOutlined';
import { Cell, CSVExporter } from '@tribiahq/interaxo-react-components';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { createStructuredSelector } from 'reselect';
import { selectCustomers } from '../../../reducers/Customers';
import Fab from '@material-ui/core/Fab';
import IconCreate from '@material-ui/icons/Add';

const styles = theme => ({
  noData: {
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    transform: 'none',
  },
  page: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  icon: {
    padding: 8,
    display: 'inline-block',
  },
});

const mapStateToProps = () =>
  createStructuredSelector({
    customers: selectCustomers(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...customersActions }, dispatch),
});

class CustomersGrid extends React.Component {
  handleEditClick = id => this.props.history.push(`/customers/${id}`);

  handleCreateClick = () => this.props.history.push(`/customers/create`);

  handleExportClick = () => {
    new CSVExporter(this.props.customers, customersColumns).doExport();
  };

  renderEditCustomerButton = ({ row }) => {
    const { classes } = this.props;

    return (
      <Cell>
        <Tooltip title={'Edit customer'}>
          <div>
            <IconButton
              className={classes.icon}
              onClick={() => this.handleEditClick(row.nodeId)}>
              {<IconEdit />}
            </IconButton>
          </div>
        </Tooltip>
      </Cell>
    );
  };

  renderToolbarButtons = () => {
    return (
      <React.Fragment>
        <Tooltip title={'Create'}>
          <Fab size="small" color="primary" onClick={this.handleCreateClick}>
            <IconCreate />
          </Fab>
        </Tooltip>
        <Tooltip title={'Export'}>
          <Button
            style={{ marginLeft: 30 }}
            variant="contained"
            onClick={this.handleExportClick}>
            Export
          </Button>
        </Tooltip>
      </React.Fragment>
    );
  };

  render() {
    const { customers, classes } = this.props;

    return (
      <Paper className={classes.page}>
        <CustomGrid
          columns={customersColumns}
          items={customers}
          renderCellComponent={getCustomerCellComponentRenderer}
          renderToolbarButtons={this.renderToolbarButtons}
          editing={{
            renderEditButton: this.renderEditCustomerButton,
          }}
          height={window.innerHeight - 155}
          onItemCreateClick={() => this.handleCreateClick()}
          onExportClick={() => this.handleCreateClick()}
        />
      </Paper>
    );
  }
}

CustomersGrid.propTypes = {
  classes: PropTypes.shape({
    noData: PropTypes.string,
    icon: PropTypes.string,
    page: PropTypes.string,
  }),
  response: PropTypes.shape({}),
  actions: PropTypes.shape({
    fetchCustomers: PropTypes.func,
  }),
  customers: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

CustomersGrid.defaultProps = {
  classes: {},
  actions: {},
  customers: [],
  isLoading: false,
  response: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(CustomersGrid));
