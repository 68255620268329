// @flow

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link as NextLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { capitalize } from '@material-ui/core/utils';

const styles = theme => ({
  root: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  variantDefault: {
    color: 'inherit',
  },
  variantPrimary: {
    color: theme.palette.primary[500],
  },
  variantAccent: {
    color: theme.palette.secondary.A400,
  },
  variantButton: {
    '&:hover': {
      textDecoration: 'inherit',
    },
  },
});

function Link(props) {
  const {
    activeClassName,
    children: childrenProp,
    classes,
    className: classNameProp,
    variant,
    href,
    prefetch,
  } = props;

  const className = classNames(
    classes.root,
    classes[`variant${capitalize(variant)}`],
    classNameProp,
  );

  const children = childrenProp;

  const rootProps = {
    href,
    prefetch,
    passhref: 'true',
  };

  const active = window.location.pathname + window.location.search === href;

  return (
    <NextLink
      to={href}
      className={classNames(className, {
        [activeClassName]: active && activeClassName,
      })}
      {...rootProps}>
      {children}
    </NextLink>
  );
}

Link.propTypes = {
  activeClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  prefetch: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'primary', 'accent', 'button']),
};

Link.defaultProps = {
  variant: 'default',
  activeClassName: '',
  className: '',
  href: '#',
  prefetch: 'false',
};

export default withStyles(styles)(Link);
