import { getEnvironmentVariables } from '../utils/Environment';

const AUTH_CONFIG = {
  client_id: getEnvironmentVariables().AUTH_CLIENT_ID,
  domain: getEnvironmentVariables().AUTH_DOMAIN,
  audience: getEnvironmentVariables().AUTH_AUDIENCE,
  scope: 'openid profile ix:sysadmin-api:all',
  redirect_uri: window.location.origin,
  userIdClaim: getEnvironmentVariables().AUTH_USER_ID_CLAIM,
};

export default AUTH_CONFIG;
