import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { createStructuredSelector } from 'reselect';
import {
  selectIsMergeUsersLoading,
  selectMergeUsersRequested,
  selectMergeUsersResponse,
} from '../../reducers/Users';
import * as notificationsActionCreators from '../../reducers/notifications';
import { bindActionCreators } from 'redux';
import { mergeUsers } from '../../thunks/Users';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';
import { Card } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Check, Close } from '@material-ui/icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    color: theme.palette.error[500],
    display: 'flex',
    flexWrap: 'wrap',
    height: '50%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40%',
    height: '100px',
  },
  buttonContainer: {
    marginLeft: '5%',
    marginRight: theme.spacing(1),
    marginTop: 26,
    width: '10%',
    height: '100px',
  },
  progressContainer: {
    height: 10,
    width: '100%',
  },
  mergeResponse: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  success: {
    color: '#00ff00',
  },
  fail: {
    color: 'error',
  },
});

const mapStateToProps = () =>
  createStructuredSelector({
    isLoading: selectIsMergeUsersLoading(),
    requested: selectMergeUsersRequested(),
    response: selectMergeUsersResponse(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      mergeUsers,
      ...notificationsActionCreators,
    },
    dispatch,
  ),
});

class MergeUsersPage extends React.Component {
  state = {
    fromValue: '',
    toValue: '',
    clean: true,
  };

  handleChangeFromValue = event =>
    this.setState({ fromValue: event.target.value });

  handleChangeToValue = event => this.setState({ toValue: event.target.value });

  handleMergeUsers = () => {
    const { fromValue, toValue } = this.state;
    this.props.actions.mergeUsers(fromValue, toValue);
    this.setState({ clean: false });
  };

  handleClear = () => this.setState({ clean: true });

  render() {
    const { classes, isLoading, response } = this.props;
    const { fromValue, toValue, clean } = this.state;

    return (
      <React.Fragment>
        <Paper className={classes.root}>
          <TextField
            id="from-value"
            className={classes.textField}
            label="From"
            placeholder="user~example.com"
            margin="normal"
            variant="outlined"
            onChange={this.handleChangeFromValue}
          />
          <TextField
            id="to-value"
            className={classes.textField}
            label="To"
            placeholder="user~example.com"
            margin="normal"
            variant="outlined"
            onChange={this.handleChangeToValue}
          />
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              disabled={fromValue === '' || toValue === '' || isLoading}
              onClick={this.handleMergeUsers}>
              {'Merge'}
            </Button>
          </div>
          <div className={classes.progressContainer}>
            <Fade in={isLoading} unmountOnExit>
              <LinearProgress />
            </Fade>
          </div>
        </Paper>
        <Card className={classes.mergeResponse}>
          <CardHeader
            title="Merge users status:"
            action={
              <Button
                variant="contained"
                color="primary"
                disabled={clean}
                onClick={this.handleClear}>
                Clear
              </Button>
            }
          />
          {!clean && (
            <CardContent>
              <List>
                {Object.entries(response).map(entry => (
                  <ListItem key={'community-' + entry[0]}>
                    <ListItemIcon>
                      {entry[1].status === 'SUCCEEDED' ? (
                        <Check className={classes.success} />
                      ) : (
                        <Close className={classes.fail} />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={entry[0]} />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          )}
        </Card>
      </React.Fragment>
    );
  }
}

MergeUsersPage.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    container: PropTypes.string,
    textField: PropTypes.string,
    buttonContainer: PropTypes.string,
    progressContainer: PropTypes.string,
    success: PropTypes.string,
    fail: PropTypes.string,
    mergeResponse: PropTypes.string,
  }),
  response: PropTypes.shape({}),
  actions: PropTypes.shape({
    mergeUsers: PropTypes.func,
  }),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
};

MergeUsersPage.defaultProps = {
  classes: {},
  actions: {},
  users: [],
  isLoading: false,
  response: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(MergeUsersPage));
