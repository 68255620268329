import { show } from '../reducers/notifications';
import { logException, newrelic } from '../store/analytics';

/**
 * All catch (error) handlers when performing axios request should go via
 * this handler. This will ensure that general errors are captured, and
 * is logged (TODO). If network errors occur, or a users session has expired,
 * a sessionError action will be dispatched which redirects the user to the login page.
 *
 * Either message OR duck needs to be provided.
 * @param  {[error]} error        Error received in response
 * @param  {[dispatch]} dispatch  redux dispatch
 * @param  {[string]} message     (optional) message key of message to show
 * @param  {[object]} action        (optional) functional specific action to handle state changes
 * ific error handler if server error
 * @return {[Promise]}
 */
export const onError = ({ error, dispatch, message, action }) => {
  if (!error.response || error.response.status === 401) {
    logException({
      type: 'SESSION',
      message: 'Session expired',
      fatal: false,
    });
  } else {
    if (error.response.status >= 500 && newrelic && newrelic.noticeError) {
      newrelic.noticeError(error);
    }

    logException({
      type: 'API',
      action: action ? action.type : '-',
      code: error.response.status,
      message: JSON.stringify(error.response.data),
      fatal: false,
    });
  }

  return dispatch(message ? show({ message }) : action);
};

export default onError;
