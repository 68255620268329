import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CallMerge from '@material-ui/icons/CallMerge';
import People from '@material-ui/icons/People';
import Community from '@material-ui/icons/EmojiPeople';
import IconButton from '@material-ui/core/IconButton';
import Home from '@material-ui/icons/Home';
import Members from '@material-ui/icons/AccountCircle';
import Tooltip from '@material-ui/core/Tooltip';

import NavLink from './NavLink';
import NavExternalLink from './NavExternalLink';

import interaxo_logo_blue from '../assets/INTERAXO_logo_blue.png';
import interaxo_logo from '../assets/INTERAXO_logo.png';
import interaxo_x from '../assets/INTERAXO_X.png';

const styles = theme => ({
  button: theme.mixins.gutters({
    borderRadius: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      textDecoration: 'none',
    },
  }),
  drawerPaper: {
    position: 'fixed',
    height: '100%',
    width: 250,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    width: 45,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
  },
  drawerInner: {
    width: 250,
    height: '100%',
    paddingTop: 0,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  drawerHeader: {
    backgroundColor: '#eafbfc',
  },
  headerInner: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
  },
  contextItem: {
    marginLeft: 26,
    marginTop: 20,
    fontSize: 13,
  },
  contextItemChild: {
    marginLeft: 50,
    fontSize: 13,
  },
  contextItemSeparator: {
    marginTop: 30,
    marginLeft: 75,
    fontSize: 13,
  },
  sidebarToggler: {
    marginLeft: 25,
  },
  interaxoIcon: {
    width: 18,
    height: 20,
    marginLeft: 13,
    marginBottom: 5,
  },
  openInteraxo: {
    width: 18,
    height: 20,
    margin: '-8px 9px -8px -10px',
    padding: '3px 10px',
  },
  interaxoIconBig: {
    width: 120,
    height: 20,
    // 46px for margin-left is result of subtraction of 57px (needed for the content
    // fits the width of sidebar) and 11px (space between content and right border of sidebar)
    marginLeft: 46,
    marginBottom: 5,
  },
});

const Sidebar = ({ classes, show, onMenuClick, activeCommunity }) => {
  return (
    <Drawer
      open={show}
      variant="permanent"
      classes={{
        paper: classNames(
          classes.drawerPaper,
          !show && classes.drawerPaperClose,
        ),
      }}>
      <List className={classes.drawerInner}>
        <div className={classes.drawerHeader}>
          <div className={classes.headerInner}>
            {!show && (
              <img
                className={classes.interaxoIcon}
                src={interaxo_x}
                alt={'X'}
              />
            )}
            {show && (
              <img
                className={classes.interaxoIconBig}
                src={interaxo_logo_blue}
                alt={'Interaxo'}
              />
            )}
            <Tooltip title="Toggle">
              <IconButton
                className={classes.sidebarToggler}
                onClick={onMenuClick}>
                <ChevronLeftIcon />
              </IconButton>
            </Tooltip>
          </div>
          <NavLink
            icon={<CallMerge style={{ marginRight: 12 }} />}
            title="Merge users"
            href={`/`}
          />
          <NavLink
            icon={<Members style={{ marginRight: 12 }} />}
            title="IT Administration"
            href={`/members`}
          />
          <NavLink
            icon={<People style={{ marginRight: 12 }} />}
            title="Customers"
            href={`/customers`}
          />
          <NavLink
            icon={<Community style={{ marginRight: 12 }} />}
            title="Communities"
            href={`/communities`}
          />
          <NavLink
            icon={<Home style={{ marginRight: 12 }} />}
            title="Rooms"
            href={`/rooms`}
          />
        </div>
        <NavExternalLink
          key={'app_bar.open_interaxo'}
          icon={
            <img
              className={classes.openInteraxo}
              src={interaxo_logo}
              alt={'X'}
            />
          }
          title={'External link'}
          href={`/`}
        />
      </List>
    </Drawer>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.shape({
    drawerPaper: PropTypes.string,
    drawerPaperClose: PropTypes.string,
    drawerInner: PropTypes.string,
    drawerHeader: PropTypes.string,
    headerInner: PropTypes.string,
    interaxoIcon: PropTypes.string,
    interaxoIconBig: PropTypes.string,
    sidebarToggler: PropTypes.string,
    openInteraxo: PropTypes.string,
  }).isRequired,
  show: PropTypes.bool.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  activeBoard: PropTypes.shape({}),
};

Sidebar.defaultProps = {
  activeBoard: null, //the very first render before activeBoard fetched
};

export default withRouter(withStyles(styles)(Sidebar));
