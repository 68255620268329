import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuth0 } from '@tribiahq/interaxo-react-components';
import { Provider } from 'react-redux';
import App from './App';
import { client } from './services/API';
import { actions as authActions } from './reducers/auth';
import configureStore from './store/configureStore';
import AUTH_CONFIG from './services/AuthConfig';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

const jwtDecode = require('jwt-decode');

const store = configureStore();

let sessionExpirationTimer;
let sessionExpirationWarningTimer;

const SESSION_EXPIRATION_TIMEOUT = 120 * 60000;
const SESSION_EXPIRATION_WARNING_TIMEOUT =
  SESSION_EXPIRATION_TIMEOUT - 15 * 60000;

const delaySessionExpiration = () =>
  setTimeout(() => {
    store.dispatch(authActions.setSessionExpired());
  }, SESSION_EXPIRATION_TIMEOUT);

const delaySessionExpirationWarning = () =>
  setTimeout(() => {
    store.dispatch(authActions.setSessionExpiring(true));
  }, SESSION_EXPIRATION_WARNING_TIMEOUT);

const handleResetSessionTimeoutTrackers = () => {
  clearTimeout(sessionExpirationTimer);
  sessionExpirationTimer = delaySessionExpiration();

  clearTimeout(sessionExpirationWarningTimer);
  sessionExpirationWarningTimer = delaySessionExpirationWarning();
};

const trackSessionExpiration = () => {
  sessionExpirationTimer = delaySessionExpiration();
  sessionExpirationWarningTimer = delaySessionExpirationWarning();

  client.interceptors.response.use(
    response => {
      handleResetSessionTimeoutTrackers();

      return response;
    },
    error => Promise.reject(error),
  );
};

const trackStorageEvents = logout => {
  window.addEventListener('storage', event => {
    if (event.key === 'isAuthenticated' && event.newValue === 'false') {
      localStorage.removeItem('isAuthenticated');
      logout();
    }

    if (
      event.key === 'refreshedAt' &&
      parseInt(event.newValue) > parseInt(event.oldValue || 0)
    ) {
      clearTimeout(sessionExpirationTimer);
      sessionExpirationTimer = delaySessionExpiration();

      clearTimeout(sessionExpirationWarningTimer);
      sessionExpirationWarningTimer = delaySessionExpirationWarning();
    }
  });
};

const Authentication = () => {
  const {
    auth0Client,
    loading,
    isAuthenticated,
    loginWithRedirect,
    getTokenSilently,
    user,
    logout,
  } = useAuth0();

  const [shouldProtect, setShouldProtect] = useState(false);

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: {
            targetUrl: `${window.location.pathname}${window.location.search}`,
          },
        });
      }
    };
    fn();
  }, [isAuthenticated, loginWithRedirect, loading]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (isAuthenticated) {
      const fn = async () => {
        const token = await getTokenSilently();
        if (
          jwtDecode(token)
            .scope.split(' ')
            .indexOf('ix:sysadmin-api:all') === -1
        ) {
          setShouldProtect(true);
        }
      };
      fn();
    }
  }, [isAuthenticated, loading, getTokenSilently, setShouldProtect]);

  // When application is still loading
  // show loading indicator
  if (loading || !isAuthenticated) {
    return (
      <div
        style={{
          height: `${window.innerHeight}px`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    );
  }

  // When already detected that currently authorized user doesn't have access
  // show appropriate Access Denied message
  if (shouldProtect) {
    return (
      <div
        style={{
          height: `${window.innerHeight}px`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <div style={{ display: 'block', textAlign: 'center' }}>
          <h1>Access denied.</h1>
          <p>Please contact administrator.</p>
          <p>
            <Tooltip title="Logout">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => logout()}>
                Logout
              </Button>
            </Tooltip>
          </p>
        </div>
      </div>
    );
  }

  // When authenticated then intercept auth info to client
  if (isAuthenticated) {
    let tokenCache = null;
    client.interceptors.request.use(async config => {
      // Settings ts param with interceptor to have it recalculated
      // for each request. So we avoid caching responses.
      config.params = config.params || {};
      config.params.ts = new Date().getTime();
      const token = await getTokenSilently();
      if (!tokenCache || (tokenCache && tokenCache.access_token !== token)) {
        tokenCache = auth0Client.cache.get({
          scope: 'openid profile email ix:sysadmin-api:all',
          audience: AUTH_CONFIG.audience,
        });
        localStorage.setItem('refreshedAt', new Date().getTime().toString());
      }

      if (token != null) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });
    store.dispatch(
      authActions.setCurrentUser({
        user: {
          name: user.name,
          nickname: user.nickname,
          picture: user.picture,
          username: user[AUTH_CONFIG.userIdClaim],
        },
      }),
    );
  }

  trackSessionExpiration();
  trackStorageEvents(logout);

  return (
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  );
};

export default Authentication;
