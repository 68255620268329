import { createSelector } from 'reselect';
import Immutable from 'seamless-immutable';

// Constants

export const types = {
  FETCH_CUSTOMERS_REQUEST: 'FETCH_CUSTOMERS_REQUEST',
  FETCH_CUSTOMERS_SUCCESS: 'FETCH_CUSTOMERS_SUCCESS',
  FETCH_CUSTOMERS_ERROR: 'FETCH_CUSTOMERS_ERROR',

  CREATE_CUSTOMER_REQUEST: 'CREATE_CUSTOMER_REQUEST',
  CREATE_CUSTOMER_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_ERROR: 'CREATE_CUSTOMER_ERROR',

  UPDATE_CUSTOMER_REQUEST: 'UPDATE_CUSTOMER_REQUEST',
  UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_ERROR: 'UPDATE_CUSTOMER_ERROR',
};

export const storeIdentifier = 'customers';

const initialState = Immutable({
  requested: false,
  isLoading: false,
  customers: [],
});

// Reducer

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_CUSTOMERS_REQUEST: {
      return state.merge({
        requested: true,
        isLoading: true,
      });
    }
    case types.FETCH_CUSTOMERS_SUCCESS: {
      return state.merge({
        isLoading: false,
        customers: action.payload.customers,
      });
    }
    case types.FETCH_CUSTOMERS_ERROR: {
      return state.merge({
        isLoading: false,
      });
    }
    case types.CREATE_CUSTOMER_REQUEST: {
      return state.merge({
        requested: true,
        isLoading: true,
      });
    }
    case types.CREATE_CUSTOMER_SUCCESS: {
      return state.merge({
        isLoading: false,
        customers: state.customers.concat(Immutable(action.payload.customer)),
      });
    }
    case types.CREATE_CUSTOMER_ERROR: {
      return state.merge({
        isLoading: false,
      });
    }
    case types.UPDATE_CUSTOMER_REQUEST: {
      return state.merge({
        requested: true,
        isLoading: true,
      });
    }
    case types.UPDATE_CUSTOMER_SUCCESS: {
      return state.merge({
        isLoading: false,
        customers: state.customers.map(customer =>
          customer.nodeId === action.payload.customer.nodeId
            ? Immutable(action.payload.customer)
            : customer,
        ),
      });
    }
    case types.UPDATE_CUSTOMER_ERROR: {
      return state.merge({
        isLoading: false,
      });
    }
    default:
      return state;
  }
}

// Actions
export const actions = {
  fetchCustomersRequest: () => ({
    type: types.FETCH_CUSTOMERS_REQUEST,
  }),

  fetchCustomersSuccess: ({ customers }) => ({
    type: types.FETCH_CUSTOMERS_SUCCESS,
    payload: { customers },
  }),

  fetchCustomersError: ({ error }) => ({
    type: types.FETCH_CUSTOMERS_ERROR,
    payload: {
      error,
    },
  }),
  createCustomerRequest: () => ({
    type: types.CREATE_CUSTOMER_REQUEST,
  }),

  createCustomerSuccess: ({ customer }) => ({
    type: types.CREATE_CUSTOMER_SUCCESS,
    payload: { customer },
  }),

  createCustomerError: ({ error }) => ({
    type: types.CREATE_CUSTOMER_ERROR,
    payload: {
      error,
    },
  }),
  updateCustomerRequest: () => ({
    type: types.UPDATE_CUSTOMER_REQUEST,
  }),

  updateCustomerSuccess: ({ customer }) => ({
    type: types.UPDATE_CUSTOMER_SUCCESS,
    payload: { customer },
  }),

  updateCustomerError: ({ error }) => ({
    type: types.UPDATE_CUSTOMER_ERROR,
    payload: {
      error,
    },
  }),
};

// Selectors
const customersSelector = () => state => state[storeIdentifier];

export const selectIsLoadingCustomers = () =>
  createSelector(
    customersSelector(),
    customersSelectorState => customersSelectorState.isLoading,
  );

export const selectIsRequestedCustomers = () =>
  createSelector(
    customersSelector(),
    customersSelectorState => customersSelectorState.requested,
  );

export const selectCustomers = () =>
  createSelector(
    customersSelector(),
    customersSelectorState => customersSelectorState.customers,
  );

export const selectCustomer = nodeId =>
  createSelector(customersSelector(), customersSelectorState =>
    customersSelectorState.customers.find(
      customer => customer.nodeId === nodeId,
    ),
  );
