import * as api from '../services/API';
import onError from './Error';
import { actions } from '../reducers/Members';
import { show } from '../reducers/notifications';

export function changeNativeCommunity({ username, users }) {
  return async dispatch => {
    dispatch(actions.changeNativeCommunityRequest());

    try {
      await api.changeNativeCommunity({ username, users });

      dispatch(show({ message: 'Native community changed successfully!' }));
      return dispatch(actions.changeNativeCommunitySuccess());
    } catch (error) {
      dispatch(
        show({
          message: `Could not change native community for user ${username} !`,
        }),
      );
      return onError({
        error,
        dispatch,
        action: actions.changeNativeCommunityError({ error }),
      });
    }
  };
}
