import * as api from '../services/API';
import onError from './Error';
import { actions } from '../reducers/Users';
import { show } from '../reducers/notifications';

export function mergeUsers(userId, toUserId) {
  return async dispatch => {
    dispatch(actions.mergeUsersRequest());

    try {
      const response = await api.mergeUsers({ userId, toUserId });

      return dispatch(
        actions.mergeUsersSuccess({ mergeUsersResponse: response.data }),
      );
    } catch (error) {
      dispatch(show({ message: 'Application error occurred!' }));
      return onError({
        error,
        dispatch,
        action: actions.mergeUsersError({ error }),
      });
    }
  };
}
