import { client } from './API';

export function fetchRooms({ communityId }) {
  return client
    .get(
      `/api/communities/${communityId}/rooms?include=workspace&enterprise=true`,
    )
    .then(response => response.data);
}

export function createTemplateRooms({ communityId, templateRooms }) {
  return client
    .post(`/api/communities/${communityId}/template-rooms`, templateRooms)
    .then(response => response.data);
}
