import PropTypes from 'prop-types';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    color: theme.palette.error[500],
  },
});

const Error = ({ classes }) => (
  <Paper className={classes.root}>
    <h2>Error</h2>
  </Paper>
);

Error.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(Error);
