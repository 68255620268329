import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const renderCustomSelectField = (
    { style, name, label, disabled, handleChange, handleBlur, error, helperText, options, className, ...other }, // eslint-disable-line
) => (
  <FormControl className={className} error={!!error}>
    {renderSelectField({
      style,
      name,
      label,
      disabled,
      handleChange,
      handleBlur,
      error,
      helperText,
      options,
      className,
      ...other,
    })}
    <FormHelperText>{helperText}</FormHelperText>
  </FormControl>
);

export const renderSelectField = (
    { style, name, label, disabled, handleChange, handleBlur, error, helperText, options, className, ...other }, // eslint-disable-line
) => (
  <>
    <InputLabel htmlFor={name}>{label}</InputLabel>
    <Select
      key={name}
      error={!!error}
      label={label}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={disabled}
      name={name}
      className={className}
      style={style}
      helperText={helperText}
      {...other}>
      {/* eslint-disable-next-line react/prop-types */}
      {options.map(option => {
        /**
         * If passing in options as objects, must match this pattern:
         * {
         *  id: 'actual value',
         *  label: 'The property label' // can be skipped
         * }
         */
        if (option instanceof Object) {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label || option.value}
            </MenuItem>
          );
        }
        return (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        );
      })}
    </Select>
  </>
);

export const renderTextField = (
    { name, type, label, placeholder, value, disabled, handleChange, handleBlur, error, helperText, ...other }, // eslint-disable-line
) => (
  <TextField
    key={name}
    name={name}
    label={label}
    value={value || ''}
    onChange={handleChange}
    onBlur={handleBlur}
    error={error}
    type={type ? type : undefined}
    helperText={helperText}
    disabled={disabled}
    placeholder={placeholder}
    {...other}
  />
);

export const renderCheckboxField = (
    { key, name, handleChange, checked, label, className, ...other}, // eslint-disable-line
) => (
  <FormControlLabel
    key={key}
    control={
      <Checkbox
        name={name}
        color="primary"
        onChange={handleChange}
        checked={checked}
        {...other}
      />
    }
    label={label}
    className={className}
  />
);
