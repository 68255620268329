import * as api from '../services/API';
import onError from './Error';
import { actions } from '../reducers/Customers';
import { show } from '../reducers/notifications';

export function fetchCustomers() {
  return async dispatch => {
    dispatch(actions.fetchCustomersRequest());

    try {
      const customers = await api.fetchCustomers();

      return dispatch(actions.fetchCustomersSuccess({ customers }));
    } catch (error) {
      dispatch(show({ message: 'Application error occurred!' }));
      return onError({
        error,
        dispatch,
        action: actions.fetchCustomersError({ error }),
      });
    }
  };
}

export function createCustomer({ data }) {
  return async dispatch => {
    dispatch(actions.createCustomerRequest());

    try {
      const customer = await api.createCustomer({ data });

      dispatch(show({ message: 'Customer successfully created!' }));
      return dispatch(actions.createCustomerSuccess({ customer }));
    } catch (error) {
      dispatch(show({ message: 'Application error occurred!' }));
      return onError({
        error,
        dispatch,
        action: actions.createCustomerError({ error }),
      });
    }
  };
}

export function updateCustomer({ customerNodeId, data }) {
  return async dispatch => {
    dispatch(actions.updateCustomerRequest());

    try {
      const customer = await api.updateCustomer({ customerNodeId, data });

      dispatch(show({ message: 'Customer successfully updated!' }));
      return dispatch(actions.updateCustomerSuccess({ customer }));
    } catch (error) {
      dispatch(show({ message: 'Application error occurred!' }));
      return onError({
        error,
        dispatch,
        action: actions.updateCustomerError({ error }),
      });
    }
  };
}
